<template>
  <div class="timeline">
   <div class="main grid md:grid-cols-4 gap-4 mx-4 mb-20">

        <div class="left-sidebar p-2 space-y-4">

            <h1 class="text-center p-4 text-2xl font-bold">Suggested Groups</h1>

           
            <div v-for="group in groups" :key="group.id" class="space-y-4 pt-2  border flex flex-col items-center rounded-md shadow-md bg-white">
            
                <img v-if="group.image" class="rounded-full w-24 h-24" :src="getfile(group.image)" alt="" srcset="">
                  <img v-else class="rounded-full w-24 h-24" src="@/assets/images/group.png" alt="" srcset="">

                  <div class="flex flex-col  items-center bg-gray-200 space-y-4 p-3 w-full">

                    <h1 class="text-gray-600">{{ group.name }}</h1>

                    <span class="text-xs text-gray-600">{{ group.group_members.length }} </span>

                    <button v-if="group.public == true" @click.prevent="joinPublic(group.id)" class="w-full text-white rounded-xl bg-indigo-900 py-2"> Join</button>
                    <button v-else @click.prevent="joinPrivate(group.id)" class="w-full text-white rounded-xl bg-indigo-900 py-2"> Request To Join</button>
                  </div>
                 
              </div>
           

            <div class="button flex justify-center">
            <router-link to="/Groups"><button class="bg-indigo-900 px-6 py-2 rounded-lg text-white hover:bg-indigo-800">View More</button></router-link>    
            </div>
           


        </div>


             <div class="main-content p-2 space-y-4 md:col-span-2 order-first md:order-none ">
                 <CreateGroupPost/>

        <!----- User TimeLine Section -->
            <div class="timeline-feeds items-center space-y-8  h-screen overflow-y-auto">
                  
                <GroupFeeds />

             </div>
  <!----- User TimeLine Section End-->
              
     
                
                </div>



        

        <div class="right-sidebar  m-2  rounded-lg space-y-4">

          

            <div v-if="memberRole.role" class="friend-card px-3 py-2 items-center bg-white flex  rounded-xl shadow-md">

                <div class="flex-1 p-2 space-y-6">
                    
                <h1 class="text-md text-center text-indigo-900 ">Unapproved Posts</h1>
            
                <div v-for="unapprove in unapproved" :key="unapprove.id" class="flex flex-col space-y-2">
                    <div class="flex items-center text-sm space-x-6">
                    <img v-if="unapprove.user.profile.image" class="object-fill w-8 h-8 rounded-xl" :src="getfile(unapprove.user.profile.image)" alt="" srcset="">
                      <img class="object-fill w-8 h-8 rounded-xl" v-else src="@/assets/images/default.png">
                    <span>{{ unapprove.user.name }}</span>
                    <span>{{ formatDate(unapprove.created_at) }}</span>
                    </div>
                   
                 
                   <p class="italic text-xs">{{ unapprove.content }}.</p>

                   <div v-if="unapprove.file" class="grid grid-cols-2 gap-3 md:grid-cols-3 py-2">
                       <div v-for="(file,index) in unapprove.file" :key="index">
                           <img class="object-fill rounded-lg" :src="getfile(file)" alt="" srcset="">
                       </div>
                   </div>

                    <button class="text-sm border border-indigo-900 rounded-lg mr-2 py-2 px-4  text-indigo-900 hover:bg-indigo-900 hover:text-white hover:border-indigo-900  ml-auto" @click.prevent="approve(unapprove.id)">Approve</button>
                </div>
                  

                </div>
        
            </div>

          

           

        
           


        </div>

    </div>

  </div>

</template>

<script>

import CreateGroupPost from '../components/CreateGroupPost.vue'
import GroupFeeds from '../components/GroupFeeds.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  name: 'Grouptimeline',
  components: {
    CreateGroupPost,
    GroupFeeds,
  },

    data() {
    return {
      unapproved: [],
      groups:[],
      form: {
        content: '',
        group_id: this.$route.params.id,
        user_id : '',
        approval_status: false
      },
      files: [],
      url: process.env.VUE_APP_BASE_URL,
      preview: [],
      memberRole:''
    }
  },

 mounted() {
    this.form.user_id = this.authenticated.id
    this.unapprovededPosts()
    this.groupUserRole()
    this.suggestedGroups()
  },

  computed: {
     ...mapGetters(['authenticated'])     
},

   methods:{
 showAlert(message){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: message,
       showConfirmations: false,
       timer: 1500,
        confirmButtonColor: '#312E81'
   });

    setTimeout(() => {
       window.location.reload();
     }, 1600);

      },

        formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },

    getfile(media){
      return this.url + `file?file=`+media    
    },

    groupUserRole(){
      this.axios.get(`group/`+this.$route.params.id+`/user/`+this.form.user_id +`/role`)
      .then((response) => {
        this.memberRole = response.data.data 
      })
      .catch((error) => {
        console.log(error)
      })
    },

    unapprovededPosts() {
      this.axios.get(`group/`+ this.$route.params.id + `/posts/unapproved`)
      .then((response) => {
        this.unapproved = response.data.data 
      })
      .catch((error) => {
        console.log(error)
      })
    },

    suggestedGroups() {
      this.axios.get(`groups`)
      .then((response) => {
        this.groups = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    approve(id) {
      this.axios.post(`group/post/approve`, {
        group_post_id: id,
        group_id: this.$route.params.id
      })
      .then(() => {        
        this.showAlert('Post Approved')
      })
      .catch((error) => {
        console.log(error)
      })
    },

 joinPublic(id){
    this.axios.post(`group_users`, {
      group_id: id,
      user_id: this.form.user_id 
    })
      .then((response) => {   
        if(response.data.message == 'Sorry, group is full'){
            this.showAlert("Sorry, Group is Full");
            return;
          }    
        this.showAlert('Successfully Joined, You are now a member') 
      })
      .catch((error) => {
        console.log(error)
      })
    
  },
  joinPrivate(id){
    this.axios.post(`group/private/join`, {
      group_id: id,
      user_id: this.form.user_id 
    })
    .then((response) => {  
      if(response.data.message == 'Sorry, group is full'){
          this.showAlert("Sorry, Group is Full");
          return;
      }     
      this.showAlert('Request Sent! Waiting For Approval')
    })
    .catch((error) => {
      console.log(error)
    })
  },

    viewComment(id) {
      this.$router.push('/post/'+ id + '/comment' )
    },

    }
}
</script>
