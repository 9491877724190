<template>
  
 <div class="grid grid-cols-1 md:grid-cols-2 w-full  mx-auto space-y-6 items-center p-10">
        <div class=" space-y-8 text-left p-4">

        <img class="w-48" src="@/assets/images/logo.png" alt="" srcset="">

         <h1 class="md:text-4xl text-gray-700">We aim to foster relationships amongst
        christians.</h1>

        </div>

       

    
            <div class=" flex flex-col w-full space-y-4 overflow-hidden p-4 md:p-10  rounded-lg bg-white">
             
           <form class="w-full lg:px-10" @submit.prevent="login">
   <p v-if="state.error" class="error-msg text-red-500 text-xs italic">{{state.error}}</p>
    <div class="flex flex-wrap mb-4">
    <div class="w-full mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
      Email
      </label>
      <input id="email" v-model="state.email" class="appearance-none block w-full  text-gray-700    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  type="email" placeholder="john@gmail.com">
      <!-- <p class="error-msg text-red-500 text-xs italic">Email is incorrect.</p> -->
    </div>

    <div class="input-errors" v-for="(error, index) of v$.email.$errors" :key="index">
        <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
    </div>

  </div>


  <div class="flex flex-wrap  mb-4">
    <div class="w-full  mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="number">
      Password
      </label>

      
    <input id="password" type="password" v-model="state.password" class="appearance-none block w-full  text-gray-700 shadow-sm   border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"   placeholder="password">
    <!-- <p class="error-msg text-red-500 text-xs italic">Password is incorrect.</p> -->
       
    </div>
 <div class="input-errors" v-for="(error, index) of v$.password.$errors" :key="index">
    <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
  </div> 
  </div>




  <div class="flex justify-center mb-4">
<button class=" bg-indigo-900 py-3 w-full rounded-md text-white">Log In</button>
  </div>

   <div class="flex flex-wrap -mx-3 mb-6">
    <div class="text-center w-full text-xs mb-6 md:mb-0">

  
        
          <h1><router-link class="text-indigo-900 "  to="/ForgotPassword">Forgot Password?</router-link></h1>
 
  
    </div>
  
  
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="text-center border-1 border-b w-full  mb-6 md:mb-0">
 
  
    </div>
  
  
  </div>

     <div class="flex flex-wrap   mb-6">
  <div class="w-full flex items-center justify-center px-3 mb-6 md:mb-0">

    <div class="privacy-check flex items-center justify-center space-x-2 text-xs ">
       <p>Don't have an account? <router-link to="/SignUp" class="text-indigo-800 hover:underline" >Register here.</router-link> </p>
    </div>

  </div>


</div>



          </form>



          </div>

      
    </div>
  
  
</template>

<script>

    import { mapGetters } from 'vuex'
    import { useVuelidate } from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'
    import { reactive, computed } from 'vue'

export default {
  name: 'Login',

   setup () {
    const state = reactive({
       email: '',
       password: '',
       error: ''
  })

   const rules = computed(() => {
      return {
          email: { required, email },
          password: { required},
      }
    })

    const v$ = useVuelidate(rules, state)

    return {
      state,
      v$
    }
  },

computed: {
     ...mapGetters(['authenticated', 'token'])     
},
  methods:{

    login() {
      this.v$.$validate()
      if(!this.v$.$error){
        this.axios.post(`login`, {
            email: this.state.email,
            password: this.state.password
          })
          .then((response) => {
            this.$store.commit('setUser', response.data.data)
            this.$router.push('/Timeline')
          })
          .catch((error) => {
            if(error.response.status === 404){
              this.state.error = 'These Credentials do not match!'
            }
          })
      }
    },

  }
}
</script>
