<template>
<div class="mt-10">
    <div class="bg-white w-full md:w-3/4 md:mx-auto  rounded-t-3xl p-8 space-y-8 shadow-md mb-20">


          <div v-if="comment.user" class=" comment-section flex  space-x-2 pb-3">

                <img class=" w-10 h-10  object-cover rounded-full" v-if="comment.user.profile && comment.user.profile.image" :src="getfile(comment.user.profile.image)">
                  <img class=" w-10 h-10  object-cover rounded-full" v-else src="@/assets/images/default.png">

                    <div class="p-4 text-xs bg-gray-200 rounded-xl space-y-2">
                        <div class="flex items-center space-x-2">
                    <span class="font-bold">{{comment.user.name}} </span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                    <span>{{formatDate(comment.created_at)}}</span>

     
                        </div>
                       
                        <p>{{comment.content}}</p>

                <div v-if="comment.file" class="grid grid-cols-2 md:grid-cols-4 gap-3">
                       <div v-for="(file,index) in comment.file" :key="index" class="pt-2">
                      
                              <img class="rounded-lg" :src="getfile(file)" alt="" srcset="">
                          
                         </div>
                      
                        </div>
                        
                        </div>


</div>

     <div v-if="comment.comment" class="flex text-indigo-900 font-medium items-center space-x-2">
                    <span class="text-sm">Comments</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                    <span>({{comment.comment.length}})</span>

     
      </div>
                       

               
   <div v-for="comment in comment.comment" :key="comment.id" class=" comment-section flex  space-x-2 pb-3">

                    <img class=" w-10 h-10  object-cover rounded-full" v-if="comment.user.profile && comment.user.profile.image" :src="getfile(comment.user.profile.image)">
                  <img class=" w-10 h-10  object-cover rounded-full" v-else src="@/assets/images/default.png">

                    <div class="p-4 text-xs bg-gray-200 rounded-xl space-y-2">
                         <div class="flex items-center space-x-2">
                    <span class="font-bold">{{comment.user.name}}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                    <span>{{formatDate(comment.created_at)}}</span>

     
                        </div>
                       <router-link :to="{name: 'Comment', params: {id: comment.id}}">{{comment.content}}</router-link>

                        
                        
                    <div v-if="comment.file" class="grid grid-cols-2 md:grid-cols-4 gap-3">
                       <div v-for="(file,index) in comment.file" :key="index" class="pt-2">
                      
                              <img class="rounded-lg" :src="getfile(file)" alt="" srcset="">
                          
                         </div>

                        </div>
            
                        
                        </div>


   </div>


<form method="POST" @submit.prevent="makeComment()">
                  
     <div class="flex items-center space-x-2 ">

                    <img class=" w-10 h-10  object-cover rounded-full" src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60">

                    <div class="flex-1 bg-gray-200 flex items-center rounded-full ">
                        <input v-model="form.content" class="bg-gray-200 rounded-full text-xs w-full py-3 px-6 text-gray-700 leading-tight focus:outline-none" id="post" type="text" placeholder="Add Comment....">
                        
                        
                    </div>


        </div>
                   <div class="flex items-center justify-between pb-6 border-b">

                  <div class="relative">
                        <img class="cursor-pointer" src="@/assets/images/picture.svg" alt="" srcset="">
                 <input @change="uploadFile" class="absolute top-0 cursor-pointer opacity-0" type="file" name="" id="" multiple>
              
                  </div>
                   


                <button type="submit" class=" items-center flex py-2 px-4 space-x-3 text-red-500 border border-indigo-900 bg-white hover:bg-red-500 hover:text-white hover:border-red-500 rounded-md">
                    <svg  xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                        <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                      </svg>
                        
                    <span >Comment</span>
                   
                </button>
                  

                     </div>
        

              <div v-if="preview.length" class="grid grid-cols-2 md:grid-cols-4 gap-3 pb-6 border-b">
                <div v-for="(img, index) in preview" :key="index" class="relative">
              
                        <button class="w-5 h-5 flex items-center justify-center bg-red-900 rounded-full absolute right-2 top-2 hover:bg-red-800 text-white">
                        <svg v-on:click="remove(index)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" data-supported-dps="16x16" fill="currentColor" class="mercado-match" width="10" height="10" focusable="false">
          <path d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"></path>
          </svg>
                                </button>
                              <img class="rounded-lg" :src="img" alt="" srcset="">
                          
                         </div>


                      
                      
                      
                   </div>
            
</form>

                   
      </div>
  </div>
    
</template>



<script>

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    name: 'TimelineComment',

  data() {
    return {
      comment: '',
      form: {
        content: '',
        commentable_id_id: this.$route.params.id,
        user_id : '',
        commentable_id_type: 'App\\Models\\Post'
      },
      files: [],
      url: process.env.VUE_APP_BASE_URL,
      preview: [],
      authenticatedUser: ''
    }
  },

 mounted() {
   this.authenticatedUser = this.authenticated
    this.post()
    this.form.user_id = this.authenticated.id
  },

  computed: {
     ...mapGetters(['authenticated'])     
},

  methods:{

  showAlert(){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: 'Comment Posted',
       showConfirmations: false,
       timer: 1500,
       confirmButtonColor: '#312E81'
   });
   
    setTimeout(() => {
       window.location.reload();
     }, 1600);
  },

    formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },

    getfile(media){
      return this.url + `file?file=`+media    
    },

    uploadFile(event) {
      let selectedFiles = event.target.files;
      if(!selectedFiles.length) {
        return false;
      }

      for (let i = 0; i < selectedFiles.length; i++){
        this.files.push(selectedFiles[i]);
        this.preview.push(URL.createObjectURL(selectedFiles[i]))
      }

    },

    post() {
      this.axios.get(`posts/`+ this.$route.params.id)
      .then((response) => {
        this.comment = response.data.data 
      })
      .catch((error) => {
        console.log(error)
      })
    },

    makeComment() {
      const formData  = new FormData()
       for (let i = 0; i < this.files.length; i++){
        formData.append('files[]', this.files[i])
      }

      formData.append('content', this.form.content);
      formData.append('commentable_id_id', this.form.commentable_id_id);
      formData.append('user_id', this.form.user_id);
      formData.append('commentable_id_type', this.form.commentable_id_type);

      const config = { headers: { 'content-type': 'multipart/form-data' } }

      this.axios.post(`comments`, formData, config)
      .then(() => {        
        this.showAlert();
      })
      .catch((error) => {
        console.log(error)
      })
    },

    remove(index){
       this.files = this.files.filter((q, i) => {
        return i !== index
      });
        this.preview = this.preview.filter((q, i) => {
        return i !== index
      });
    },
    

  }
}

</script>