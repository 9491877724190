<template>
   
               
                    <tr v-for="(friend, index) in nonFriends" :key="friend.id" class="border-b mb-10 border-gray-200">
                       <td v-if="friend.status == 'PENDING' " class="p-3"> {{ index + 1}}  </td>
                    <td v-if="friend.status == 'PENDING' " class="p-3 flex items-center "> <img v-if="friend.profile.image" class="h-8 w-8 mr-2 rounded-lg" :src="getfile(friend.profile.image)" alt="" srcset="">
                  <img v-else class="h-8 w-8 mr-2 rounded-lg" src="@/assets/images/default.png" alt="" srcset="">
                     </td>
                    <td v-if="friend.status == 'PENDING' " class="p-3 text-left">{{ friend.name }}</td>
                    <td v-if="friend.status == 'PENDING' " class="p-3 text-left">{{ friend.profile.phone ? friend.profile.phone : 'Rather not say' }}</td>
                    <td v-if="friend.status == 'PENDING' " class="p-3 text-left">{{ friend.profile.marital_status ? friend.profile.marital_status : 'Rather not say' }}</td>
                   



                    <td v-if="friend.status == 'PENDING' "  class="p-3">
                        <span class="px-6 py-2 rounded-xl text-white bg-red-400">
                     Pending
                   </span>
                      
                      
                      </td>
       

                  </tr>








</template>



<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
    name: 'Pending',

  data() {
    return {
    toggle : false,
    nonFriends: [],
    url: process.env.VUE_APP_BASE_URL,
    authenticatedUser: '',
    }

},

 computed: {
     ...mapGetters(['authenticated'])     
},

 mounted() {
    this.authenticatedUser = this.authenticated
    this.getNonFriends()
},

 methods:{
    
    formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },

    getfile(media){
      return this.url + `file?file=`+media    
    },

    getNonFriends() {
      this.axios.get(`add/friends`)
      .then((response) => {
        this.nonFriends = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },
    
    },

}


</script>