<template>
<div>
  <Header v-if ="!$route.meta.hideHeader"/>
    <router-view/>
  <Footer v-if ="!$route.meta.hideFooter"/>
</div>


</template>

<style lang="">


</style>

<script>
import Header from '../src/components/Header.vue'
import Footer from '../src/components/Footer.vue'

export default {
  name: 'App',

  components:{
    Header,
    Footer
  }
  
}
</script>

<style scoped>

</style>
