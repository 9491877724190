<template>
  <div class="about">
    <main class="mt-10">
      <div class="bg-white rounded-t-3xl p-8 space-y-8 shadow-md mb-20">
        <div class="flex items-center justify-end">
          <div v-if="create_group" class="button">
            <button
              class="
                flex
                space-x-2
                items-center
                px-6
                py-3
                border
                rounded-2xl
                border-indigo-900
                text-indigo-900
                shadow-md
                hover:bg-indigo-100
              "
              @click="$refs.modalName.openModal()"
            >
              <img src="@/assets/images/plus.svg" alt="" srcset="" />
              <span>Create Group</span>
            </button>
          </div>
        </div>

        <tabs :options="{ useUrlFragment: false }">
          <tab name="My Groups" class="overflow-x-auto">
            <div class="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
              <div
                v-for="group in groupsIbelong"
                :key="group.id"
                class="
                  border
                  space-y-4
                  flex flex-col
                  items-center
                  p-6
                  rounded-md
                  shadow-md
                  text-center
                  bg-white
                "
              >
                <img
                  v-if="group.image"
                  class="rounded-full w-24 h-24"
                  :src="getfile(group.image)"
                  alt=""
                  srcset=""
                />
                <img
                  v-else
                  class="rounded-full w-24 h-24"
                  src="@/assets/images/group.png"
                  alt=""
                  srcset=""
                />

                <h1 class="text-gray-600">{{ group.name }}</h1>
                <p class="text-gray-600 text-xs">{{ group.description }}</p>
                <div class="space-x-4">
                  <span class="text-xs text-gray-600"
                    >{{ group.group_users.length }} member(s)
                  </span>
                  <span class="text-xs text-gray-600"
                    >Created {{ formatDate(group.created_at) }}
                  </span>
                </div>

                <div class="mt-3">
                  <router-link
                    :to="{ name: 'MyGroupPosts', params: { id: group.id } }"
                    ><span
                      class="
                        text-sm
                        border
                        rounded-lg
                        mr-2
                        py-2
                        px-4
                        bg-indigo-900
                        text-white
                      "
                      >View</span
                    ></router-link
                  >
                  <button
                    class="
                      text-sm text-white
                      border
                      bg-red-500
                      rounded-lg
                      py-2
                      px-4
                    "
                    @click="showExitAlert(group.id)"
                  >
                    Exit
                  </button>
                </div>
              </div>
            </div>
          </tab>

          <tab name="Public Group" class="overflow-x-auto">
            <div class="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
              <div
                v-for="group in publicGroups"
                :key="group.id"
                class="
                  space-y-4
                  pt-2
                  border
                  flex flex-col
                  items-center
                  rounded-md
                  shadow-md
                "
              >
                <img
                  v-if="group.image"
                  class="rounded-full w-24 h-24"
                  :src="getfile(group.image)"
                  alt=""
                  srcset=""
                />
                <img
                  v-else
                  class="rounded-full w-24 h-24"
                  src="@/assets/images/group.png"
                  alt=""
                  srcset=""
                />

                <div
                  class="
                    flex flex-col
                    items-center
                    bg-gray-200
                    space-y-4
                    p-3
                    w-full
                  "
                >
                  <h1 class="text-gray-600">{{ group.name }}</h1>

                  <span class="text-xs text-gray-600"
                    >{{ group.group_members.length }} member(s)
                  </span>

                  <button
                    @click.prevent="joinPublic(group.id)"
                    class="w-full text-white rounded-xl bg-indigo-900 py-2"
                  >
                    Join
                  </button>
                </div>
              </div>
            </div>
          </tab>

          <tab name="Private Group" class="overflow-x-auto">
            <div class="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
              <div
                v-for="group in privateGroups"
                :key="group.id"
                class="
                  space-y-4
                  pt-2
                  border
                  flex flex-col
                  items-center
                  rounded-md
                  shadow-md
                "
              >
                <img
                  v-if="group.image"
                  class="rounded-full w-24 h-24"
                  :src="getfile(group.image)"
                  alt=""
                  srcset=""
                />
                <img
                  v-else
                  class="rounded-full w-24 h-24"
                  src="@/assets/images/group.png"
                  alt=""
                  srcset=""
                />

                <div
                  class="
                    flex flex-col
                    items-center
                    bg-gray-200
                    space-y-4
                    p-3
                    w-full
                  "
                >
                  <h1 class="text-gray-600">{{ group.name }}</h1>

                  <span class="text-xs text-gray-600"
                    >{{ group.group_members.length }} member(s)
                  </span>

                  <button
                    @click.prevent="joinPrivate(group.id)"
                    class="w-full text-white rounded-xl bg-indigo-900 py-2"
                  >
                    Request to Join
                  </button>
                </div>
              </div>
            </div>
          </tab>

          <tab name="Group invites" class="overflow-x-auto">
            <div class="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
              <div
                v-for="group in groupInvites"
                :key="group.id"
                class="
                  space-y-4
                  pt-2
                  border
                  flex flex-col
                  items-center
                  rounded-md
                  shadow-md
                "
              >
                <img
                  v-if="group.group.image"
                  class="rounded-full w-24 h-24"
                  :src="getfile(group.group.image)"
                  alt=""
                  srcset=""
                />
                <img
                  v-else
                  class="rounded-full w-24 h-24"
                  src="@/assets/images/group.png"
                  alt=""
                  srcset=""
                />

                <div
                  class="
                    flex flex-col
                    items-center
                    bg-gray-200
                    space-y-4
                    p-3
                    w-full
                  "
                >
                  <h1 class="text-gray-600">{{ group.group.name }}</h1>

                  <span class="text-xs text-gray-600"
                    >{{ group.group.group_users.length }} member(s)
                  </span>

                  <button
                    @click.prevent="acceptGroupInvite(group.group_id, group.id)"
                    class="w-full text-white rounded-xl mr-2 bg-indigo-900 py-2"
                  >
                    Accept Invite
                  </button>
                  <button
                    @click.prevent="cancelGroupInvite(group.id)"
                    class="w-full text-white rounded-xl bg-red-500 py-2"
                  >
                    Decline Invite
                  </button>
                </div>
              </div>
            </div>
          </tab>

          <tab name="Invite " class="overflow-x-auto">
            <table class="w-full table-auto">
              <thead>
                <tr
                  class="
                    bg-indigo-900
                    text-white text-xs
                    leading-normal
                    text-wiifarm-500
                  "
                >
                  <td class="p-3">S/N</td>
                  <td class="p-3 text-left">Name</td>
                  <td class="p-3 text-left">Description</td>
                  <td class="p-3 text-left">Members</td>

                  <td class="p-3">Action</td>
                </tr>
              </thead>

              <tbody class="text-gray-600 text-xs font-medium">
                <tr
                  v-for="(group, index) in visibleGroups"
                  :key="group.id"
                  class="border-b mb-10 border-gray-200"
                >
                  <td class="p-3">{{ index + 1 }}</td>
                  <td class="p-3 flex items-center">
                    <img v-if="group.image"
                      class="h-8 w-8 mr-2 rounded-lg"
                      :src="getfile(group.image)"
                      alt=""
                      srcset=""
                    />
                         <img
                  v-else
                  class="h-8 w-8 mr-2 rounded-lg"
                  src="@/assets/images/group.png"
                  alt=""
                  srcset=""
                />
                    {{ group.name }}
                  </td>
                  <td class="p-3 text-left">{{ group.description }}</td>
                  <td class="p-3 text-left">{{ group.group_users.length }}</td>

                  <td class="p-3">
                    <router-link
                      v-if="group.public == true"
                      :to="{ name: 'InviteMembers', params: { id: group.id } }"
                    >
                      <span
                        class="px-6 py-2 bg-indigo-900 rounded-md text-white"
                        >Invite</span
                      ></router-link
                    >
                    <router-link
                      v-else
                      :to="{ name: 'InviteMembers', params: { id: group.id } }"
                    >
                      <span
                        class="px-6 py-2 bg-indigo-900 rounded-md text-white"
                        >Add</span
                      ></router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </tab>

          <tab name="Groups You Created" class="overflow-x-auto">
            <table class="w-full table-auto">
              <thead>
                <tr
                  class="
                    bg-indigo-900
                    text-white text-xs
                    leading-normal
                    text-wiifarm-500
                  "
                >
                  <td class="p-3">S/N</td>
                  <td class="p-3 text-left">Name</td>
                  <td class="p-3 text-left">Description</td>
                  <td class="p-3 text-left">Members</td>
                  <td class="p-3 text-left">Created</td>
                  <td class="p-3">Action</td>
                </tr>
              </thead>

              <tbody class="text-gray-600 text-xs font-medium">
                <tr
                  v-for="(group, index) in myGroups"
                  :key="group.id"
                  class="border-b mb-10 border-gray-200"
                >
                  <td class="p-3">{{ index + 1 }}</td>
                  <td class="p-3 flex items-center">
                    <img
                      v-if="group.image"
                      class="h-8 w-8 mr-2 rounded-lg"
                      :src="getfile(group.image)"
                      alt=""
                      srcset=""
                    />
                         <img
                  v-else
                  class="h-8 w-8 mr-2 rounded-lg"
                  src="@/assets/images/group.png"
                  alt=""
                  srcset=""
                />
                    {{ group.name }}
                  </td>
                  <td class="p-3 text-left">{{ group.description }}</td>
                  <td class="p-3 text-left">{{ group.group_users.length }}</td>
                  <td class="p-3 text-left">
                    {{ formatDate(group.created_at) }}
                  </td>

                  <td class="p-3">
                    <div>
                      <Popper arrow>
                        <button>
                          <img
                            src="@/assets/images/3-dots.svg"
                            alt=""
                            srcset=""
                          />
                        </button>
                        <template #content>
                          <div
                            class="text-sm text-center flex flex-col space-y-3"
                          >
                            <router-link
                              :to="{
                                name: 'MyGroupPosts',
                                params: { id: group.id },
                              }"
                              class="hover:text-indigo-500 w-full"
                              >View</router-link
                            >
                            <router-link
                              :to="{
                                name: 'EditGroup',
                                params: { id: group.id },
                              }"
                              class="hover:text-indigo-500 w-full"
                              >Edit</router-link
                            >

                            <router-link
                              :to="{
                                name: 'GroupMembers',
                                params: { id: group.id },
                              }"
                              class="hover:text-indigo-500 w-full"
                              >Members</router-link
                            >

                            <button
                              class="hover:text-indigo-500 w-full"
                              @click="showCancelAlert(group.id)"
                            >
                              Delete
                            </button>
                          </div>
                        </template>
                      </Popper>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </tab>
        </tabs>
      </div>
    </main>
  </div>

  <modal ref="modalName">
    <template v-slot:header>
      <div class="text-center">
        <h1 class="text-center font-sans text-2xl text-wiifarm-500">
          Creat a Group
        </h1>
      </div>
    </template>

    <template v-slot:body>
      <form class="w-full lg:px-10" method="POST" @submit.prevent="create">
        <div class="flex flex-wrap mb-6">
          <div
            class="w-full mb-6 md:mb-0"
            :class="{ error: v$.form.name.$errors.length }"
          >
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Group Name
            </label>
            <input
              id="name"
              v-model="v$.form.name.$model"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="text"
              placeholder="John"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.name.$errors"
              :key="index"
            >
              <p class="error-msg text-red-500 text-xs italic">
                {{ error.$message }}.
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="w-full mb-6 md:mb-0"
            :class="{ error: v$.form.description.$errors.length }"
          >
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Group Description
            </label>
            <textarea
              rows="4"
              v-model="v$.form.description.$model"
              cols="50"
              class="
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type=""
              placeholder="description about the group..."
            ></textarea>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.description.$errors"
              :key="index"
            >
              <p class="error-msg text-red-500 text-xs italic">
                {{ error.$message }}.
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-4">
          <div class="w-full mb-6 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for=""
            >
              Group Image
            </label>
            <input
              @change="uploadPics"
              class="
                block
                w-full
                text-gray-700
                hadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
                text-xs
              "
              type="file"
              placeholder=""
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-4">
          <div class="w-full mb-6 md:mb-0 text-xs text-center italic">
            <span
              >if Visible checked, anyone can see the group and request to join,
              else members can only be added by members of the group</span
            >
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="flex items-center jus md:w-1/2 mb-6 md:mb-0 space-x-2">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold"
              for=""
            >
              Public
            </label>
            <input
              type="checkbox"
              v-model="form.public"
              value="{{value}}"
              name=""
              id=""
            />
          </div>
          <div
            v-if="!form.public"
            class="
              flex
              justify-end
              items-center
              md:w-1/2
              mb-6
              md:mb-0
              space-x-2
            "
          >
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold"
              for=""
            >
              Visible
            </label>
            <input
              type="checkbox"
              v-model="form.visible"
              value="{{value}}"
              name=""
              id=""
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-4">
          <div
            class="w-full md:w-1/2 mb-6 md:mb-0"
            :class="{ error: v$.form.category_id.$errors.length }"
          >
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for=""
            >
              Category
            </label>
            <select v-model="v$.form.category_id.$model" id="cat" v-on:change="sortBy" class=" block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs">
                <option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</option>
              
            </select>
                     <div class="input-errors" v-for="(error, index) of v$.form.category_id.$errors" :key="index">
              <div class="error-msg text-red-500 text-xs italic">{{ error.$message }}</div>
          </div>
          </div>
    
       <div v-if="selectedCat" class="w-full md:w-1/2  pl-3 mb-6 md:mb-0">
            <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
            Streams
            </label>
            <select v-model="form.stream_id" id="myStream" class=" block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  >
                <option v-for="stream in streams" :key="stream.id" :value="stream.id" :id="stream.name">{{stream.name}}</option>
            </select>
            <p v-if="stre" class="error-msg text-red-500 text-xs italic"> Stream is required</p>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="w-full mb-6 md:mb-0"
            :class="{ error: v$.form.max_members.$errors.length }"
          >
            <label
              class="block tracking-wide mb-2 text-gray-700 text-xs font-bold"
              for=""
            >
              Maximum Members
            </label>
            <input
              v-model="v$.form.max_members.$model"
              class="
                block
                w-full
                appearance-none
                text-gray-700
                hadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
                text-xs
              "
              type="number"
              placeholder=""
            />
          </div>
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.max_members.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div class="flex justify-center mb-4">
          <button
            type="submit"
            :disabled="v$.form.$invalid"
            class="bg-indigo-900 py-3 w-full rounded-md text-white"
          >
            Save
          </button>
        </div>
      </form>
    </template>

    <!-- <template v-slot:footer>
        
      </template> -->
  </modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import moment from "moment";

export default {
  name: "Groups",

  components: {
    Modal,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      groupPosts: [],
      groups: [],
      publicGroups: [],
      privateGroups: [],
      myGroups: [],
      groupsIbelong: [],
      visibleGroups: [],
      categories: [],
      streams: [],
      groupInvites: [],
      form: {
        name: "",
        description: "",
        public: false,
        visible: false,
        max_members: "",
        created_by: "",
        category_id: "",
        stream_id: "",
        image: "",
      },
      value: true,
      updateGroup: false,
      file: "",
      url: process.env.VUE_APP_BASE_URL,
      auth_user_role: "",
      create_group: false,
      authenticatedUser: '',
      selectedCat: false,
      SelCat:'',
      stre:false
    }
  },

  validations: {
    form: {
      name: { required },
      description: { required },
      max_members: { required },
      category_id: { required },
    },


  },

  computed: {
    ...mapGetters(["authenticated", "role"]),
  },

  mounted() {
    this.authenticatedUser = this.authenticated;
    this.posts();
    this.form.created_by = this.authenticated.id;
    this.createdGroups();
    this.suggestedGroups();
    this.GroupsIbelong();
    this.userVisibleGroups();
    this.auth_user_role = this.role ? this.role.role.permissions : "";
    this.permission();
    this.category();
    this.stream();
    this.GroupsInvites();
  },

  methods: {
    showAlert(message) {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: message,
        showConfirmations: false,
        timer: 1500,
        confirmButtonColor: "#312E81",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1700);
    },
    showExitAlert(id) {
      this.$swal({
        position: "top-end",
        title: "Are you sure you want to Exit group?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#312E81",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Exit",
      }).then((result) => {
        if (result.isConfirmed) {
          this.existGroup(id);
        }
      });
    },

    existedAlert(message) {
      this.$swal(message),
        setTimeout(() => {
          window.location.reload();
        }, 1700);
    },

    showCancelAlert(id) {
      this.$swal({
        position: "top-end",
        title: "Are you sure you want to delete group?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#312E81",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteGroup(id);
        }
      });
    },

    deletedAlert() {
      this.$swal("Deleted!", "Group has been removed! ", "Success"),
        setTimeout(() => {
          this.$router.push("/group");
        }, 1700);
    },

    permission() {
      if (
        this.auth_user_role &&
        this.auth_user_role.indexOf("can_create_group") !== -1
      ) {
        this.create_group = true;
      }
    },

    formatDate(value) {
      if (value) {
        return moment(value).fromNow();
      }
    },
    getfile(media) {
      return this.url + `file?file=` + media;
    },
    uploadPics(event) {
      this.form.image = event.target.files[0];
    },
    posts() {
      this.axios
        .get(`group_posts`)
        .then((response) => {
          this.groupPosts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    create() {
      if(this.SelCat === 'OTHERS' && this.form.stream_id == ''){
        this.stre=true
        return ;
      }
      if (this.form.public == true) {
        this.form.visible = true;
      }

      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      formData.append("public", this.form.public);
      formData.append("visible", this.form.visible);
      formData.append("max_members", this.form.max_members);
      formData.append("created_by", this.form.created_by);
      formData.append("category_id", this.form.category_id);
      formData.append("stream_id", this.form.stream_id);
      formData.append("pics", this.form.image);

      const config = { headers: { "content-type": "multipart/form-data" } };

      this.axios
        .post(`groups`, formData, config)
        .then(() => {
          this.showAlert("Group Creation Successful");
          // window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createdGroups() {
      this.axios
        .get(`user/groups/created`)
        .then((response) => {
          this.myGroups = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GroupsInvites() {
      this.axios
        .get(`group_invites`)
        .then((response) => {
          this.groupInvites = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteGroup(id) {
      this.axios
        .delete(`groups/${id}`)
        .then(() => {
          this.deletedAlert();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    suggestedGroups() {
      this.axios
        .get(`groups`)
        .then((response) => {
          this.groups = response.data.data;
          this.publicGroups = this.groups.filter(function (e) {
            return e.public == true;
          });
          this.privateGroups = this.groups.filter(function (e) {
            return e.public == false;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GroupsIbelong() {
      this.axios
        .get(`user/groups`)
        .then((response) => {
          this.groupsIbelong = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    userVisibleGroups() {
      this.axios
        .get(`user/groups`)
        .then((response) => {
          this.visibleGroups = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    invite(id, general) {
      this.$router.push({
        path: "/group/" + id + "/invite",
        query: { public: general },
      });
    },

    joinPublic(id) {
      this.axios
        .post(`group_users`, {
          group_id: id,
          user_id: this.authenticated.id,
        })
        .then(() => {
          this.showAlert("Successfully Joined, You are now a member");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    joinPrivate(id) {
      this.axios
        .post(`group/private/join`, {
          group_id: id,
          user_id: this.authenticated.id,
        })
        .then(() => {
          this.showAlert("Request Sent! Waiting For Approval");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    existGroup(id) {
      this.axios
        .delete(`group/` + id + `/exit`)
        .then(() => {
          this.existedAlert("Deleted!", "You have Exited Group! ", "Success");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    category() {
      this.axios
        .get(`categories`)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    stream() {
      this.axios
        .get(`streams`)
        .then((response) => {
          this.streams = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    acceptGroupInvite(group, invite) {
      this.axios.get(`groups/` + group).then((response) => {
        this.group = response.data.data;

        if (this.group.public == true) {
          this.axios
            .post(`group_users`, {
              group_id: group,
              user_id: this.form.created_by,
            })
            .then(() => {
              this.MarkInviteAccepted(invite);
              // window.location.reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (this.group.public == false && this.group.visible == false) {
          this.axios
            .post(`group/private/add`, {
              group_id: group,
              user_id: this.form.created_by,
            })
            .then(() => {
              this.MarkInviteAccepted(invite);
              // window.location.reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.axios
            .post(`group/private/join`, {
              group_id: group,
              user_id: this.form.created_by,
            })
            .then(() => {
              this.MarkInviteAccepted(invite);
              // window.location.reload();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    MarkInviteAccepted(invite) {
      this.axios
        .put(`group_invites/${invite}`, {
          status: "accepted",
        })
        .then(() => {
          this.showAlert("Group Invite Accepted");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelGroupInvite(invite) {
      this.axios
        .put(`group_invites/${invite}`, {
          status: "declined",
        })
        .then(() => {
          this.existedAlert("Group Invite Declined");
        })
        .catch((error) => {
          console.log(error)
        })
  },
    sortBy: function() {
      var cat = document.getElementById("cat");
      this.SelCat = cat.options[cat.selectedIndex].text;

      if(this.SelCat === 'OTHERS' ){
         this.selectedCat =true
      }else{
        this.selectedCat = false
      }

    },

  }
}
</script>

<style scoped>
</style>