<template>
  <div class="timeline">
    <div class="main grid md:grid-cols-4 gap-4 md:mx-4 mb-20">
      <div class="left-sidebar p-2 space-y-4">
        <h1 class="text-center p-4 text-2xl font-bold">Suggested Groups</h1>

        <div
          class="
            space-y-4
            pt-2
            border
            flex flex-col
            items-center
            rounded-md
            shadow-md
            bg-white
          "
        >
          <img
            class="rounded-full w-24 h-24"
            src="https://portalpopline.com.br/wp-content/uploads/2020/09/justin-bieber-nova-era-2020.jpg"
            alt=""
            srcset=""
          />

          <div
            class="flex flex-col items-center bg-gray-200 space-y-4 p-3 w-full"
          >
            <h1 class="text-gray-600">Jay Group</h1>

            <span class="text-xs text-gray-600">43 members </span>

            <button class="w-full text-white rounded-xl bg-indigo-900 py-2">
              Join
            </button>
          </div>
        </div>

        <div
          class="
            space-y-4
            pt-2
            border
            flex flex-col
            items-center
            rounded-md
            shadow-md
            bg-white
          "
        >
          <img
            class="rounded-full w-24 h-24"
            src="https://portalpopline.com.br/wp-content/uploads/2020/09/justin-bieber-nova-era-2020.jpg"
            alt=""
            srcset=""
          />

          <div
            class="flex flex-col items-center bg-gray-200 space-y-4 p-3 w-full"
          >
            <h1 class="text-gray-600">Jay Tech</h1>

            <span class="text-xs text-gray-600">43 members </span>

            <button class="w-full text-white rounded-xl bg-indigo-900 py-2">
              Request to Join
            </button>
          </div>
        </div>

        <div class="button flex justify-center">
          <router-link to="/Groups"
            ><button
              class="
                bg-indigo-900
                px-6
                py-2
                rounded-lg
                text-white
                hover:bg-indigo-800
              "
            >
              View More
            </button></router-link
          >
        </div>
      </div>

      <div
        class="
          main-content
          p-2
          space-y-4
          md:col-span-2
          order-first
          md:order-none
        "
      >
        <CreateGroupPost />

        <!----- User TimeLine Section -->
        <div
          class="timeline-feeds items-center space-y-8 h-screen overflow-y-auto"
        >
          <GroupFeeds />
          <GroupFeeds />
        </div>
        <!----- User TimeLine Section End-->
      </div>

      <div class="right-sidebar m-2 rounded-lg space-y-4">
        <div
          class="
            friend-card
            px-3
            py-2
            items-center
            bg-white
            flex
            rounded-xl
            shadow-md
          "
        >
          <div class="flex-1 p-2 space-y-4">
            <h1 class="text-md text-center text-indigo-900">
              Suggested Members in Group
            </h1>

            <div class="flex items-center text-md">
              <div class="flex items-center space-x-6">
                <img
                  class="object-fill w-8 h-8 rounded-xl"
                  src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  srcset=""
                />
                <span>Faith Johnson</span>
              </div>

              <span
                class="
                  text-sm
                  border border-indigo-900
                  rounded-lg
                  mr-2
                  py-2
                  px-4
                  text-indigo-900
                  hover:bg-indigo-900 hover:text-white hover:border-indigo-900
                  cursor-pointer
                  ml-auto
                "
                >Add</span
              >
            </div>

            <div class="flex items-center text-md">
              <div class="flex items-center space-x-6">
                <img
                  class="object-fill w-8 h-8 rounded-xl"
                  src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  srcset=""
                />
                <span>Faith Johnson</span>
              </div>

              <span
                class="
                  text-sm
                  border border-indigo-900
                  rounded-lg
                  mr-2
                  py-2
                  px-4
                  text-indigo-900
                  hover:bg-indigo-900 hover:text-white hover:border-indigo-900
                  cursor-pointer
                  ml-auto
                "
                >Add</span
              >
            </div>

            <div class="flex items-center text-md">
              <div class="flex items-center space-x-6">
                <img
                  class="object-fill w-8 h-8 rounded-xl"
                  src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  srcset=""
                />
                <span>Faith Johnson</span>
              </div>

              <span
                class="
                  text-sm
                  border border-indigo-900
                  rounded-lg
                  mr-2
                  py-2
                  px-4
                  text-indigo-900
                  hover:bg-indigo-900 hover:text-white hover:border-indigo-900
                  cursor-pointer
                  ml-auto
                "
                >Add</span
              >
            </div>

            <div class="flex items-center text-md">
              <div class="flex items-center space-x-6">
                <img
                  class="object-fill w-8 h-8 rounded-xl"
                  src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  srcset=""
                />
                <span>Faith Johnson</span>
              </div>

              <span
                class="
                  text-sm
                  border border-indigo-900
                  rounded-lg
                  mr-2
                  py-2
                  px-4
                  text-indigo-900
                  hover:bg-indigo-900 hover:text-white hover:border-indigo-900
                  cursor-pointer
                  ml-auto
                "
                >Add</span
              >
            </div>

            <div class="flex items-center text-md">
              <div class="flex items-center space-x-6">
                <img
                  class="object-fill w-8 h-8 rounded-xl"
                  src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                  alt=""
                  srcset=""
                />
                <span>Faith Johnson</span>
              </div>

              <span
                class="
                  text-sm
                  border border-indigo-900
                  rounded-lg
                  mr-2
                  py-2
                  px-4
                  text-indigo-900
                  hover:bg-indigo-900 hover:text-white hover:border-indigo-900
                  cursor-pointer
                  ml-auto
                "
                >Add</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import CreateGroupPost from "../components/CreateGroupPost.vue";
import GroupFeeds from "../components/GroupFeeds.vue";

export default {
  name: "Grouptimeline",

  components: {
    CreateGroupPost,
    GroupFeeds,
  },

  data() {
    return {
      approvedPosts: [],
      unapproved: [],
      form: {
        content: "",
        group_id: this.$route.params.id,
        user_id: "",
        approval_status: false,
      },
      files: [],
      url: process.env.VUE_APP_BASE_URL,
      preview: [],
      memberRole: "",
    };
  },

  mounted() {
    this.form.user_id = this.authenticated.id;
    this.posts();
    this.unapprovededPosts();
    this.groupUserRole();
  },

  computed: {
    ...mapGetters(["authenticated"]),
  },

  methods: {
    formatDate(value) {
      if (value) {
        return moment(value).fromNow();
      }
    },

    getfile(media) {
      return this.url + `file?file=` + media;
    },

    uploadFile(event) {
      let selectedFiles = event.target.files;

      if (!selectedFiles.length) {
        return false;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        this.files.push(selectedFiles[i]);
        this.preview.push(URL.createObjectURL(selectedFiles[i]));
      }
    },

    posts() {
      this.axios
        .get(`group/` + this.$route.params.id + `/posts`)
        .then((response) => {
          this.approvedPosts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    groupUserRole() {
      this.axios
        .get(
          `group/` +
            this.$route.params.id +
            `/user/` +
            this.form.user_id +
            `/role`
        )
        .then((response) => {
          this.memberRole = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    unapprovededPosts() {
      this.axios
        .get(`group/` + this.$route.params.id + `/posts/unapproved`)
        .then((response) => {
          this.unapproved = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    approve(id) {
      this.axios
        .post(`group/post/approve`, {
          group_post_id: id,
          group_id: this.$route.params.id,
        })
        .then(() => {
          this.$router.push("/group");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    makePost() {
      const formData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        formData.append("files[]", this.files[i]);
      }

      formData.append("content", this.form.content);
      formData.append("group_id", this.form.group_id);
      formData.append("user_id", this.form.user_id);
      formData.append("approval_status", 0);

      const config = { headers: { "content-type": "multipart/form-data" } };

      this.axios
        .post(`group_posts`, formData, config)
        .then(() => {
          // this.$router.push('/dashboard')
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    viewComment(id) {
      this.$router.push("/post/" + id + "/comment");
    },

    upload() {
      document.getElementById("file_upload_id").click();
    },
    remove(index) {
      this.files = this.files.filter((q, i) => {
        return i !== index;
      });
      this.preview = this.preview.filter((q, i) => {
        return i !== index;
      });
    },
  },
};
</script>
