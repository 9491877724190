<template>
  <div
    v-if="Posts.length === 0"
    class="space-y-4 bg-white px-6 py-6 rounded-xl shadow-sm"
  >
    <div class="flex flex-1 items-center 
    justify-between">
      <span><em>No post yet...</em></span>
    </div>
  </div>
  
  <div v-else class="mb-4">
    <div
      v-for="post in Posts"
      :key="post.id"
      class="mb-4 bg-white px-6
       py-6 rounded-xl shadow-sm"
    >
      <div v-if="!post.friend && !post.group" > 
        <div class="flex items-center space-x-2 pb-3">
          <img
            class="w-10 h-10 object-cover rounded-full"
            v-if="post.user.profile && post.user.profile.image"
            :src="getfile(post.user.profile.image)"
          />
          <img
            class="w-10 h-10 object-cover rounded-full"
            v-else
            src="@/assets/images/default.png"
          />
          <div class="flex flex-1 items-center justify-between">
            <div>
              <span class="text-indigo-900 text-sm">{{ post.user.name }}</span>
              <div class="flex items-center">
                <div class="space-y-1 text-xs">
                  <div class="flex space-x-2">
                    <span>{{ formatDate(post.created_at) }}</span>
                    <span>Public</span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <img src="@/assets/images/eclipse.svg" alt="" />
            </div>
          </div>
        </div>

        <div class="post-update mb-4 space-y-4">
          <router-link
            :to="{ name: 'TimelineComment', params: { id: post.id } }"
            >{{ post.content }}</router-link
          >

          <div
            v-if="post.file"
            class="post-images grid grid-cols-2 md:grid-cols-3 gap-2"
          >
            <div v-for="(file, index) in post.file" :key="index" class="">
              <img
                class="w-48 h-48 rounded-xl object-cover"
                :src="getfile(file)"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>

        <div
          class="
            post-interaction
            flex
            text-gray-500 text-sm
            justify-between
            items-center
            border-t border-b
            py-3
            md:px-6
            mb-4
          "
        >
          <div class="flex items-center space-x-2">
            <span>Like </span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
              /></svg
            ><span class="text-red-500">100</span>
          </div>

          <div class="flex items-center space-x-1">
            <span>Comment</span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              /></svg
            ><span class="text-red-500">{{ post.comment.length }}</span>
          </div>

          <div class="flex items-center space-x-1">
            <span>Share </span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"
              />
            </svg>
          </div>
        </div>

        <form method="POST" @submit.prevent="makeComment(post.id)">
          <div class="Comment-Input items-center space-x-2 pb-3 border-b space-y-4">
            <div class="flex items-center space-x-3">
              <img
                class="w-10 h-10 object-cover rounded-full"
                src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
              />

              <div class="w-full bg-gray-200 flex items-center rounded-full">
                <input
                  v-model="form.content"
                  class="
                    bg-gray-200
                    rounded-full
                    text-xs
                    w-full
                    py-3
                    px-6
                    text-gray-700
                    leading-tight
                    focus:outline-none
                  "
                  id="post"
                  type="text"
                  placeholder="Comment...."
                />
              </div>
            </div>

            <div class="flex pt-3 items-center justify-between mb-4">
              <div class="flex items-center">
                <div class="relative">
                  <img
                    class="cursor-pointer"
                    src="@/assets/images/picture.svg"
                    alt=""
                    srcset=""
                  />
                  <input
                    multiple
                    @change="uploadFile"
                    class="absolute top-0 cursor-pointer opacity-0"
                    type="file"
                    name=""
                    id=""
                  />
                </div>
              </div>
              <button
                type="submit"
                class="
                  items-center
                  flex
                  py-2
                  px-4
                  space-x-3
                  text-red-500
                  border border-indigo-900
                  bg-white
                  hover:bg-red-500 hover:text-white hover:border-red-500
                  rounded-md
                "
              >
                comment
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"
                  />
                  <path
                    d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"
                  />
                </svg>
              </button>
            </div>

            <div
              v-if="preview.length"
              class="grid grid-cols-2 md:grid-cols-4 gap-3"
            >
              <div
                v-for="(img, index) in preview"
                :key="index"
                class="relative"
              >
                <button
                  class="
                    w-5
                    h-5
                    flex
                    items-center
                    justify-center
                    bg-red-900
                    rounded-full
                    absolute
                    right-2
                    top-2
                    hover:bg-red-800
                    text-white
                  "
                >
                  <svg
                    v-on:click="remove(index)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    data-supported-dps="16x16"
                    fill="currentColor"
                    class="mercado-match"
                    width="10"
                    height="10"
                    focusable="false"
                  >
                    <path
                      d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
                    ></path>
                  </svg>
                </button>
                <img :src="img" class="rounded-lg" alt="" srcset="" />
              </div>
            </div>
          </div>
        </form>

        <div v-if="post.comment">
          <div
            v-for="comment in post.comment.slice(0, 1)"
            :key="comment.id"
            class="comment-section flex space-x-2 pb-3 pt-3"
          >
            <img
              class="w-10 h-10 object-cover lg:rounded-full"
              v-if="comment.user && comment.user.profile.image"
              :src="getfile(comment.user.profile.image)"
            />
            <img
              class="w-10 h-10 object-cover lg:rounded-full"
              v-else
              src="@/assets/images/default.png"
            />

            <div class="p-4 text-xs bg-gray-200 rounded-xl space-y-2">
              <div class="flex items-center space-x-2">
                <span class="font-bold"> {{ comment.user.name }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <span>{{ formatDate(comment.created_at) }}</span>
              </div>
              <router-link
                :to="{ name: 'Comment', params: { id: comment.id } }"
                >{{ comment.content }}</router-link
              >

              <div
                v-if="comment.file"
                class="grid grid-cols-2 md:grid-cols-4 gap-3"
              >
                <div
                  v-for="(file, index) in comment.file"
                  :key="index"
                  class="pt-2"
                >
                  <img
                    class="rounded-lg w-28 h-28 object-cover"
                    :src="getfile(file)"
                    alt=""
                    srcset=""
                  />
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="post.friend && post.friend.posts">
        <div v-for="pos in post.friend.posts" :key="pos.id">
          <div class="flex items-center space-x-2 pb-3">
            <img
              class="w-10 h-10 object-cover rounded-full"
              v-if="pos.user.profile && pos.user.profile.image"
              :src="getfile(pos.user.profile.image)"
            />
            <img
              class="w-10 h-10 object-cover rounded-full"
              v-else
              src="@/assets/images/default.png"
            />
            <div class="flex flex-1 items-center justify-between">
              <div>
                <span class="text-indigo-900 text-sm">{{ pos.user.name }}</span>
                <div class="flex items-center">
                  <div class="space-y-1 text-xs">
                    <div class="flex space-x-2">
                      <span>{{ formatDate(pos.created_at) }}</span>
                      <span>Public</span>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <img src="@/assets/images/eclipse.svg" alt="" />
              </div>
            </div>
          </div>

          <div class="post-update space-y-4">
            <router-link
              :to="{ name: 'TimelineComment', params: { id: pos.id } }"
              >{{ pos.content }}</router-link
            >

            <div
              v-if="pos.file"
              class="post-images grid grid-cols-2 md:grid-cols-3 gap-2"
            >
              <div v-for="(file, index) in pos.file" :key="index" class="">
                <img
                  class="w-48 h-48 rounded-xl object-cover"
                  :src="getfile(file)"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>

          <div
            class="
              post-interaction
              flex
              text-gray-500 text-sm
              justify-between
              items-center
              border-t border-b
              py-3
              md:px-6
            "
          >
            <div class="flex items-center space-x-2">
              <span>Like </span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
                /></svg
              ><span class="text-red-500">100</span>
            </div>

            <div class="flex items-center space-x-1">
              <span>Comment</span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                /></svg
              ><span class="text-red-500">{{ pos.comment.length }}</span>
            </div>

            <div class="flex items-center space-x-1">
              <span>Share </span
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"
                />
              </svg>
            </div>
          </div>

          <form method="POST" @submit.prevent="makeComment(pos.id)">
            <div class="Comment-Input items-center space-x-2 pb-3 border-b">
              <div class="flex items-center space-x-3">
                <img
                  class="w-10 h-10 object-cover rounded-full"
                  src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
                />

                <div class="w-full bg-gray-200 flex items-center rounded-full">
                  <input
                    v-model="form.content"
                    class="
                      bg-gray-200
                      rounded-full
                      text-xs
                      w-full
                      py-3
                      px-6
                      text-gray-700
                      leading-tight
                      focus:outline-none
                    "
                    id="post"
                    type="text"
                    placeholder="Comment...."
                  />
                </div>
              </div>

              <div class="flex pt-3 items-center justify-between">
                <div class="flex items-center">
                  <div class="relative">
                    <img
                      class="cursor-pointer"
                      src="@/assets/images/picture.svg"
                      alt=""
                      srcset=""
                    />
                    <input
                      multiple
                      @change="uploadFile"
                      class="absolute top-0 cursor-pointer opacity-0"
                      type="file"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  class="
                    items-center
                    flex
                    py-2
                    px-4
                    space-x-3
                    text-red-500
                    border border-indigo-900
                    bg-white
                    hover:bg-red-500 hover:text-white hover:border-red-500
                    rounded-md
                  "
                >
                  comment
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"
                    />
                    <path
                      d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"
                    />
                  </svg>
                </button>
              </div>

              <div
                v-if="preview.length"
                class="grid grid-cols-2 md:grid-cols-4 gap-3"
              >
                <div
                  v-for="(img, index) in preview"
                  :key="index"
                  class="relative"
                >
                  <button
                    class="
                      w-5
                      h-5
                      flex
                      items-center
                      justify-center
                      bg-red-900
                      rounded-full
                      absolute
                      right-2
                      top-2
                      hover:bg-red-800
                      text-white
                    "
                  >
                    <svg
                      v-on:click="remove(index)"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      data-supported-dps="16x16"
                      fill="currentColor"
                      class="mercado-match"
                      width="10"
                      height="10"
                      focusable="false"
                    >
                      <path
                        d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
                      ></path>
                    </svg>
                  </button>
                  <img :src="img" class="rounded-lg" alt="" srcset="" />
                </div>
              </div>
            </div>
          </form>

          <div v-if="pos.comment">
            <div
              v-for="comment in pos.comment.slice(0, 1)"
              :key="comment.id"
              class="comment-section flex space-x-2 pb-3"
            >
              <img
                class="w-10 h-10 object-cover rounded-full"
                v-if="comment.user && comment.user.profile.image"
                :src="getfile(comment.user.profile.image)"
              />
              <img
                class="w-10 h-10 object-cover rounded-full"
                v-else
                src="@/assets/images/default.png"
              />

              <div class="p-4 text-xs bg-gray-200 rounded-xl space-y-2">
                <div class="flex items-center space-x-2">
                  <span class="font-bold"> {{ comment.user.name }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                  <span>{{ formatDate(comment.created_at) }}</span>
                </div>
                <router-link
                  :to="{ name: 'Comment', params: { id: comment.id } }"
                  >{{ comment.content }}</router-link
                >

                <div
                  v-if="comment.file"
                  class="grid grid-cols-2 md:grid-cols-4 gap-3"
                >
                  <div
                    v-for="(file, index) in comment.file"
                    :key="index"
                    class="pt-2"
                  >
                    <img
                      class="rounded-lg"
                      :src="getfile(file)"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="post.group">
        <div class="flex items-center space-x-2 pb-3">
          <img
            class="w-10 h-10 object-cover rounded-full"
            v-if="post.user.profile && post.user.profile.image"
            :src="getfile(post.user.profile.image)"
          />
          <img
            class="w-10 h-10 object-cover rounded-full"
            v-else
            src="@/assets/images/default.png"
          />
          <div class="flex flex-1 items-center justify-between">
            <div>
              <span class="text-indigo-900 text-sm">{{ post.user.name }}</span>
              <div class="flex items-center">
                <div class="space-y-1 text-xs">
                  <div class="flex space-x-2">
                    <span>{{ formatDate(post.created_at) }}</span>
                    <span>Public</span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <img src="@/assets/images/eclipse.svg" alt="" />
            </div>
          </div>
        </div>

        <div class="post-update space-y-4">
          <router-link
            class="post-description text-xs"
            :to="{ name: 'GroupComment', params: { id: post.id } }"
            >{{ post.content }}</router-link
          >

          <div
            v-if="post.file"
            class="post-images grid grid-cols-2 md:grid-cols-3 gap-2"
          >
            <div v-for="(file, index) in post.file" :key="index" class="">
              <img
                class="w-48 h-48 rounded-xl object-cover"
                :src="getfile(file)"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>

        <div
          class="
            post-interaction
            flex
            text-gray-500 text-sm
            justify-between
            items-center
            border-t border-b
            py-3
            md:px-6
          "
        >
          <div class="flex items-center space-x-2">
            <span>Like </span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
              /></svg
            ><span class="text-red-500">100</span>
          </div>

          <div class="flex items-center space-x-1">
            <span>Comment</span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              /></svg
            ><span class="text-red-500">{{ post.comment.length }}</span>
          </div>

          <div class="flex items-center space-x-1">
            <span>Share </span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3 w-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"
              />
            </svg>
          </div>
        </div>

        <form method="POST" @submit.prevent="makeGroupComment(post.id)">
          <div class="Comment-Input items-center space-x-2 pb-3 border-b">
            <div class="flex items-center space-x-3">
              <img
                class="w-10 h-10 object-cover rounded-full"
                src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
              />

              <div class="w-full bg-gray-200 flex items-center rounded-full">
                <input
                  v-model="form.content"
                  class="
                    bg-gray-200
                    rounded-full
                    text-xs
                    w-full
                    py-3
                    px-6
                    text-gray-700
                    leading-tight
                    focus:outline-none
                  "
                  id="post"
                  type="text"
                  placeholder="Comment...."
                />
              </div>
            </div>

            <div class="flex pt-3 items-center justify-between">
              <div class="flex items-center">
                <div class="relative">
                  <img
                    class="cursor-pointer"
                    src="@/assets/images/picture.svg"
                    alt=""
                    srcset=""
                  />
                  <input
                    multiple
                    @change="uploadFile"
                    class="absolute top-0 cursor-pointer opacity-0"
                    type="file"
                    name=""
                    id=""
                  />
                </div>
              </div>

              <button
                type="submit"
                class="
                  items-center
                  flex
                  py-2
                  px-4
                  space-x-3
                  text-red-500
                  border border-indigo-900
                  bg-white
                  hover:bg-red-500 hover:text-white hover:border-red-500
                  rounded-md
                "
              >
                comment
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"
                  />
                  <path
                    d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"
                  />
                </svg>
              </button>
            </div>

            <div
              v-if="preview.length"
              class="grid grid-cols-2 md:grid-cols-4 gap-3"
            >
              <div
                v-for="(img, index) in preview"
                :key="index"
                class="relative"
              >
                <button
                  class="
                    w-5
                    h-5
                    flex
                    items-center
                    justify-center
                    bg-red-900
                    rounded-full
                    absolute
                    right-2
                    top-2
                    hover:bg-red-800
                    text-white
                  "
                >
                  <svg
                    v-on:click="remove(index)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    data-supported-dps="16x16"
                    fill="currentColor"
                    class="mercado-match"
                    width="10"
                    height="10"
                    focusable="false"
                  >
                    <path
                      d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
                    ></path>
                  </svg>
                </button>
                <img :src="img" class="rounded-lg" alt="" srcset="" />
              </div>
            </div>
          </div>
        </form>

        <div v-if="post.comment">
          <div
            v-for="comment in post.comment.slice(0, 1)"
            :key="comment.id"
            class="comment-section flex space-x-2 pb-3"
          >
            <img
              class="w-10 h-10 object-cover rounded-full"
              v-if="comment.user && comment.user.profile.image"
              :src="getfile(comment.user.profile.image)"
            />
            <img
              class="w-10 h-10 object-cover rounded-full"
              v-else
              src="@/assets/images/default.png"
            />

            <div class="p-4 text-xs bg-gray-200 rounded-xl space-y-2">
              <div class="flex items-center space-x-2">
                <span class="font-bold"> {{ comment.user.name }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
                <span>{{ formatDate(comment.created_at) }}</span>
              </div>
              <router-link
                :to="{ name: 'Comment', params: { id: comment.id } }"
                >{{ comment.content }}</router-link
              >

              <div
                v-if="comment.file"
                class="grid grid-cols-2 md:grid-cols-4 gap-3"
              >
                <div
                  v-for="(file, index) in comment.file"
                  :key="index"
                  class="pt-2"
                >
                  <img
                    class="rounded-lg"
                    :src="getfile(file)"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "TimelineFeeds",

  data() {
    return {
      Posts: [],
      url: process.env.VUE_APP_BASE_URL,
      authenticatedUser: "",
      form: {
        content: "",
        commentable_id_id: "",
        user_id: "",
        commentable_id_type: "App\\Models\\Post",
      },
      files: [],
      preview: [],
      user: {
        content: "",
        commentable_id_id: "",
        user_id: "",
        commentable_id_type: "App\\Models\\GroupPost",
      },
    };
  },

  mounted() {
    this.authenticatedUser = this.authenticated;
    this.posts();
    this.form.user_id = this.authenticated.id;
  },

  computed: {
    ...mapGetters(["authenticated"]),
  },

  methods: {
    showAlert() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Comment Posted",
        showConfirmations: false,
        timer: 1500,
        confirmButtonColor: "#312E81",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1600);
    },

    formatDate(value) {
      if (value) {
        return moment(value).fromNow();
      }
    },

    getfile(media) {
      return this.url + `file?file=` + media;
    },

    posts() {
      this.axios
        .get(`timeline`)
        .then((response) => {
          this.Posts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    uploadFile(event) {
      let selectedFiles = event.target.files;
      if (!selectedFiles.length) {
        return false;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        this.files.push(selectedFiles[i]);
        this.preview.push(URL.createObjectURL(selectedFiles[i]));
      }
    },

    makeComment(id) {
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files[]", this.files[i]);
      }

      formData.append("content", this.form.content);
      formData.append("commentable_id_id", id);
      formData.append("user_id", this.form.user_id);
      formData.append("commentable_id_type", this.form.commentable_id_type);

      const config = { headers: { "content-type": "multipart/form-data" } };

      this.axios
        .post(`comments`, formData, config)
        .then(() => {
          this.showAlert();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    makeGroupComment(id) {
      const formData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        formData.append("files[]", this.files[i]);
      }

      formData.append("content", this.user.content);
      formData.append("commentable_id_id", id);
      formData.append("user_id", this.user.user_id);
      formData.append("commentable_id_type", this.user.commentable_id_type);

      const config = { headers: { "content-type": "multipart/form-data" } };

      this.axios
        .post(`comments`, formData, config)
        .then(() => {
          this.showAlert();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    remove(index) {
      this.files = this.files.filter((q, i) => {
        return i !== index;
      });
      this.preview = this.preview.filter((q, i) => {
        return i !== index;
      });
    },
  },
};
</script>