/* eslint-disable vue/no-deprecated-filter */
<template>
       <div v-for="event in events" :key="event.id" class="event-card border space-y-3 px-3 py-2  bg-white rounded-xl shadow-md" >

               <div class="w-full flex justify-end">
                <Popper  arrow hover>
                <button>    <img src="@/assets/images/3-dots.svg" alt="" srcset=""></button>
                <template #content="{close}">
      
                    <div class="text-sm text-center space-y-3">
                <span><router-link :to="{name: 'Event-details', params: {id: event.id}}" class="hover:text-indigo-500 w-full" href="" v-on:click="close" @click=" Options =!Options">View Details</router-link></span>

                <button class="hover:text-indigo-500  w-full" v-on:click="close" href="" @click.prevent="register(event.id)">Register</button>
                    </div>
                
            </template>
                </Popper>

               </div>
       
                <div class="grid grid-cols-1 lg:grid-cols-2 items-center ">
                    <div class="rounded-xl" >
                       <img v-if="event.images" class="object-fill rounded-xl shadow border" :src="getfile(event.images)" alt="" srcset="">
                       <img v-else class="object-fill rounded-xl shadow border" src="@/assets/images/event.jpeg" alt="" srcset="">
                        
                    </div>
    
                    <div class="p-4 space-y-2">
                        
                            <h1 class="text-xl text-indigo-900 ">{{ event.title }}</h1>
                  
                            <p class="text-xs text-gray-400">{{ trim(event.details)   }}.</p>
                        
                       
    
                        <div class="details space-y-1">
                            <span class="text-xs text-indigo-900 font-bold">Details</span>
                            <div class="flex items-center text-xs ">
                                <img src="/icons /task.svg" alt="" srcset="">
                                <span>Venue: {{ event.location }}</span>
                            </div>
    
                            <div class="flex items-center text-xs ">
                                <img src="/icons /calendar.svg" alt="" srcset="">
                                <span>Date: {{ event.start_date }} {{ event.end_date ? '-' + ''+ event.end_date : '' }}</span>
                            </div>
    
                        </div>
    
                    </div>

                   
                </div>

        
            </div>
</template>



<script>

    import moment from 'moment'
    import { mapGetters } from 'vuex'

export default {
    name : 'AllEventCard',


data() {
    return{
         Options: false,
         events: [],
         url: process.env.VUE_APP_BASE_URL,
         form:{
            created_by: ''
         } ,
    }

},

 computed: {
     ...mapGetters(['authenticated'])     
},

 mounted() {
    this.getEvents()
    this.form.created_by = this.authenticated.id
},

  methods:{

    trim(s, len){
      if(!len) len = 50;
      if (s.length < len) return s;
      return s.substring(0,len-3) + '...'
    },


      showAlert(){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: 'Registered Successfully',
       showConfirmations: false,
       timer: 1500,
        confirmButtonColor: '#312E81'
   });
   
    setTimeout(() => {
       window.location.reload();
     }, 1600);
      },
    
    formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },
    getfile(media){
      return this.url + `file?file=`+media    
    },

    getEvents() {
      this.axios.get(`events`)
      .then((response) => {
        this.events = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    register(id){
      this.axios.post(`event_users`, {
        event_id: id,
        user_id : this.form.created_by
      })
      .then(() => {  
        this.showAlert()     
      })
      .catch((error) => {
        console.log(error)
      })
    },
  },



}
</script>


<style scoped>

 
</style>