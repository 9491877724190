<template>
   

              

               
                    <tr v-for="(friend, index) in nonFriends" :key="friend.id" class="border-b mb-10 border-gray-200">
                    <td class="p-3"> {{ index + 1}}  </td>
                    <td class="p-3 flex items-center "> 
                       <img v-if="friend.profile.image" class="h-8 w-8 mr-2 rounded-lg" :src="getfile(friend.profile.image)" alt="" srcset="">
                  <img v-else class="h-8 w-8 mr-2 rounded-lg" src="@/assets/images/default.png" alt="" srcset="">  
                      </td>
                    <td class="p-3 text-left">{{ friend.name }}</td>
                    <td class="p-3 text-left">{{ friend.profile.phone ? friend.profile.phone : 'Rather not say' }}</td>
                    <td class="p-3 text-left">{{ friend.profile.marital_status ? friend.profile.marital_status : 'Rather not say' }}</td>
                   



                    <td class="p-3">
                          <div class="w-full">
                <Popper  arrow hover >
                <button>    <img src="@/assets/images/3-dots.svg" alt="" srcset=""></button>
                <template #content ="{ close }">
      
                    <div class="text-sm text-center flex flex-col space-y-3">
                 <a href="#" v-on:click="close" @click.prevent="add(friend.id)" class="hover:text-indigo-500 w-full">Add Friend 😎</a>
              <router-link :to="{name: 'FriendProfile', params: {id: friend.id}}" class="hover:text-indigo-500 w-full">View Profile</router-link>
                
                    </div>
                
            </template>
                </Popper>

               </div>
                      </td>

                  </tr>








</template>



<script>

import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
    name: 'AddFriends',

  data() {
    return {
    toggle : false,
    allNonFriends: [],
    nonFriends: [],
    url: process.env.VUE_APP_BASE_URL,
    authenticatedUser: '',
    }

},

 computed: {
     ...mapGetters(['authenticated'])     
},

 mounted() {
    this.authenticatedUser = this.authenticated
    this.getNonFriends()
},

 methods:{
 showAlert(){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: 'Friend Request Sent',
       showConfirmations: false,
       timer: 1500,
        confirmButtonColor: '#312E81'
   });

   setTimeout(() => {
       window.location.reload();
     }, 1600);

      },
    
    formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },

    getfile(media){
      return this.url + `file?file=`+media    
    },

    getNonFriends() {
      this.axios.get(`add/friends`)
      .then((response) => {
        this.allNonFriends = response.data.data
        console.log(this.allNonFriends)
        this.nonFriends = this.allNonFriends.filter(function (e) {
            return !e.status;
        });
      })
      .catch((error) => {
        console.log(error)
      })
    },

    add(id) {
      this.axios.post(`friends`,{
        user_id: this.authenticatedUser.id,
        friend_id: id,
        status : 'sent'
      })
      .then(() => {
        this.showAlert()
      })
      .catch((error) => {
        console.log(error)
      })
    },
    
    },



}


</script>