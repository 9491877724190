<template>
     <div  v-for="event in mineEvents" :key="event.id" class="event-card border space-y-3 px-3 py-2  bg-white rounded-xl shadow-md" >

 <div class="w-full flex justify-end">
                <Popper  arrow hover>
                <button>    <img src="@/assets/images/3-dots.svg" alt="" srcset=""></button>
                <template #content="{close}">
      
                    <div class="text-sm text-center space-y-3">
                <span><router-link :to="{name: 'Event-registered', params: {id: event.event.id}}" class="hover:text-indigo-500 w-full" href="" v-on:click="close">View Details</router-link></span>

                    </div>
                
            </template>
                </Popper>
        </div>
       
              
                <div class="grid grid-cols-1 lg:grid-cols-2 items-center ">
                    <div class="rounded-xl" >
                       <img v-if="event.event.images" class="object-fill rounded-xl shadow border" :src="getfile(event.event.images)" alt="" srcset="">
                        <img v-else class="object-fill rounded-xl shadow border" src="@/assets/images/event.jpeg" alt="" srcset="">
                    </div>
    
                    <div class="p-4 space-y-2">
                            <div class="flex items-center space-x-3">
  <h1 class="text-xl text-indigo-900 ">Title
                                :
                            </h1>
                            <p class="text-xs text-gray-400">{{ event.event.title }}.</p>
                            </div>
                          
            
    
                        <div class="details space-y-3">
                            <span class="text-xs text-xs text-gray-400">{{trim(event.event.details)}}</span>
                            <div class="flex items-center text-xs ">
                                <img src="/icons /task.svg" alt="" srcset="">
                                <span>Venue: {{ event.event.location }}</span>
                            </div>
    
                            <div class="flex items-center text-xs ">
                                <img src="/icons /calendar.svg" alt="" srcset="">
                                <span>Date: {{ event.event.start_date }} {{ event.event.end_date ? '-' + ''+ event.event.end_date : '' }}</span>
                            </div>

                           
                    <button class="px-6 py-2 bg-red-500 rounded-md text-white hover:bg-red-600 " @click.prevent="showCancelAlert(event.id)">cancel</button>
               
         
    
                        </div>
    
                    </div>

                   
                </div>

        
            </div>
</template>



<script>

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    name : 'RegisteredEventCard',

  data() {
    return {
      mineEvents: [],
      url: process.env.VUE_APP_BASE_URL,
    }
  },

computed: {
     ...mapGetters(['authenticated', 'role'])     
},

 mounted() {
    this.registeredEvents()
},

  methods:{
       trim(s, len){
      if(!len) len = 50;
      if (s.length < len) return s;
      return s.substring(0,len-3) + '...'
    },

    formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },
    getfile(media){
      return this.url + `file?file=`+media    
    },

    showCancelAlert(id){
      this.$swal({
         position: 'top-end',
         title:'Are you sure you want to cancel?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#312E81',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, remove it!',
      }).then((result) => {
          if (result.isConfirmed) {
            this.cancel(id)
          }
      });
      
  },

  deletedAlert(){
      this.$swal(
          'Deleted!',
          'Registration has been canceled ',
          'Success'
      ),

    setTimeout(() => {
       window.location.reload();
     }, 1600);
  },

   registeredEvents() {
      this.axios.get(`event_users`)
      .then((response) => {
        this.mineEvents = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    cancel(id) {
      this.axios.delete(`event_users/`+id)
      .then(() => {
         this.deletedAlert()
      })
      .catch((error) => {
        console.log(error)
      })
    },
   
  },


}
</script>