<template>
    <div id="event-details">
  <main class=" md:m-10">
 

      <div class="w-full md:w-10/12 mx-auto rounded-md p-8 space-y-4">
        <router-link class="hover:text-indigo-500" to="/Events">
         <button class="text-xs flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
</svg>

<span>Back</span>
          </button>
        
        </router-link>   
        
            <img v-if="events.images" class="w-full object-cover rounded-lg" :src="getfile(events.images)" alt="" srcset="">

        <div class="flex gap-4 flex-col md:flex-row overflow-auto  rounded-lg space-y-6 md:space-y-0">
            <div class="md:w-1/3 text-sm text-indigo-900 rounded-lg border shadow-lg bg-white space-y-2">

                <div class="border-b p-2  ">
                    <span>Title: {{ events.title }}</span>
                </div>
                 <div class="border-b p-2  ">
                   <span>Location:
                    {{ events.location }}</span>
                </div>
                 <div class="border-b p-2  ">
                   <span>Start Time:
                    {{ events.start_time }}</span>
                </div>
                 <div class="border-b p-2  ">
                <span>Closing Time:
                    {{ events.end_time ? events.end_time : 'Closing Time not specified' }}</span>
                </div>
                 <div class="border-b p-2  ">
                  <span>Start Date:
                    {{ events.start_date }}</span>
                </div>
                <div class="border-b p-2  ">
                  <span>End Date:
                    {{ events.end_date ? events.end_date : 'End Date not specified' }}</span>
                </div>
                 <div class="p-2  ">
                    <span>Seats Number:
                        {{ events.seats ? events.seats : 'Number Of Seats not specified' }}</span>
                </div>

                 <div class="flex justify-center p-4">
                    <button class="px-6 py-2 bg-indigo-900 rounded-md text-white hover:bg-indigo-600 " @click.prevent="register(events.id)">Register</button>
               
                </div>
            
            </div>
            <div class="flex-1 bg-white text-sm space-y-2 p-4 rounded-lg shadow-lg border">
                <span class="text-lg">Description</span>
                <h1>
                    {{ events.details ? events.details : 'Details not specified' }}
                </h1>
               
            </div>
        </div>
         
      </div>
    </main>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Event-details',

   data() {
    return {
      events: [],
      form:{
        created_by: ''
      } ,
      url: process.env.VUE_APP_BASE_URL,
    }
  },

 computed: {
     ...mapGetters(['authenticated'])     
},

 mounted() {
    this.getEvent()
    this.form.created_by = this.authenticated.id
},

    methods:{
 showAlert(){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: 'Registered Successfully',
       showConfirmations: false,
       timer: 1500,
        confirmButtonColor: '#312E81'
   });

   setTimeout(() => {
        this.$router.push('/Events' )
     }, 1600);

      },

    getfile(media){
      return this.url + `file?file=`+media    
    },

    getEvent() {
      this.axios.get(`events/`+this.$route.params.id)
      .then((response) => {
        this.events = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    register(id){
      this.axios.post(`event_users`, {
        event_id: id,
        user_id : this.form.created_by
      })
      .then(() => {  
        this.showAlert()     
       
      })
      .catch((error) => {
        console.log(error)
      })
    },

    }
}
</script>