<template>
  <div class="">
   <div class="grid md:grid-cols-2 w-full mx-auto items-center m-4 p-4 md:p-10">
       <div class=" space-y-8 text-left p-4 ">

        <img class="w-48" src="@/assets/images/logo.png" alt="" srcset="">

         <h1 class="md:text-4xl text-gray-700">We aim to foster relationships amongst
        christians.</h1>

        </div>



         <div class=" flex flex-col w-full space-y-4 overflow-hidden p-6  rounded-lg bg-white">
                    
   <form class="w-full lg:px-10" method="POST" @submit.prevent="register">
      <p v-if="state.error" class="error-msg text-red-500 text-xs italic">{{state.error}}</p>
      <div class="flex flex-wrap mb-6">
        <div class="w-full mb-6 md:mb-0">
          <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="firstname">
            Name
          </label>
          <input id="name" v-model="state.form.name" class="appearance-none block w-full  text-gray-700 shadow-sm  border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight text-xs focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1"  type="text" placeholder="John">
        
          <!-- <p class="error-msg text-red-500 text-xs italic">Please fill out this field.</p> -->
        </div>
        <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
         <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
        </div>
      </div>

    <div class="flex flex-wrap mb-4">
    <div class="w-full   mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
      Email
      </label>
      <input id="email" v-model="state.form.email" class="appearance-none block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  type="email" placeholder="john@gmail.com">
      
    </div>
      <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
        <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
      </div>
  </div>


  <div class="flex flex-wrap  mb-4">
    <div class="w-full  mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="number">
      Password
      </label>

      <div class=" relative">
    <input id="password" v-model="state.form.password" type="password"  class="appearance-none block w-full  text-gray-700 shadow-sm   border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"   placeholder="password">
     
      </div>
    </div>
     <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
       <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
    </div>

  </div>

  <div class="flex flex-wrap mb-4">
    <div class="w-full mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="number">
      Confirm Password
      </label>


    <div class=" relative">
    <input id="confirm-password" v-model="state.form.password_confirmation" class="appearance-none block w-full shadow-sm    text-gray-700  border focus:border-indigo-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-100 focus:ring-indigo-700 focus:ring-1 text-xs"  type="password"  placeholder="confirm password">
   
      </div>


     
    </div>
    <div class="input-errors" v-for="(error, index) of v$.form.password_confirmation.$errors" :key="index">
     <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
    </div>

  </div>

<div class="flex flex-wrap mb-4">
  <div class="w-full flex items-center justify-center px-3 mb-6 md:mb-0">

    <div class="privacy-check flex items-center justify-center space-x-2 text-xs ">

      <div class="flex">
        <input id="check" type="checkbox" required  name="check"  class="">
      </div>

      <div>
<p>I agree to the <a href="#" class="text-wiifarm-600">Terms of Use</a> & <a href="#" class="text-wiifarm-600">Privacy Policy</a> </p>
      </div>

    </div>

  </div>


</div>




  <div class="flex justify-center mb-6">
<button type="submit" class=" bg-indigo-900 py-3 w-full rounded-md text-white">Register</button>
  </div>


<div class="flex flex-wrap -mx-3 mb-6">
    <div class="text-center border-1 border-b w-full  mb-6 md:mb-0">
 
  
    </div>
  
  
  </div>


     <div class="flex flex-wrap mb-6">
  <div class="w-full flex items-center justify-center px-3 mb-6 md:mb-0">

    <div class="privacy-check flex items-center justify-center space-x-2 text-xs ">
       <p>Already have an account ? <router-link to="/" class="text-indigo-800 hover:underline">Login here.</router-link></p>
    </div>

  </div>


</div>



          </form>



          </div>

        
    </div>
  </div>
</template>


<script>

   import { useVuelidate } from '@vuelidate/core'
   import { required, email, minLength, sameAs } from '@vuelidate/validators'
   import { reactive, computed } from 'vue'

export default {
    name: 'SignUp',

   setup () {
    const state = reactive({
       form:{
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      } ,
      error: ''
  })

   const rules = computed(() => {
      return {
          form: {
            name: { required },
            email: { required, email },
            password: { required, minLength: minLength(6)},
            password_confirmation: {required, sameAs: sameAs(state.form.password) }
          }
      }
    })

    const v$ = useVuelidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods:{

    register() {
      this.v$.$validate()
      if(!this.v$.$error){
        this.axios.post(`register`, this.state.form)
        .then(() => {
          this.$router.push('/')
        })
        .catch((error) => {
            if(error.response.status === 422){
              this.state.error= "Email already exist."
          }
        })
      }
    },

  }
}
</script>