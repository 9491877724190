<template>
  <div class="mt-10  ">
    <div class="bg-white rounded-t-3xl p-8 space-y-8 shadow-md mb-20">
 <router-link class="hover:text-indigo-500" to="/Groups">
         <button class="text-xs flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
</svg>

<span>Back</span>
          </button>
        
        </router-link>   
            <tabs :options="{ useUrlFragment: false }" >

                <tab class="space-y-8" name="Active Members">
     <table class=" w-full table-auto ">
             <thead >
                  <tr class=" bg-indigo-900 text-white text-xs leading-normal text-wiifarm-500">
                    <td class="p-3"> S/N  </td>
                    <td class="p-3 text-left">Name</td>
                    <td class="p-3 text-left">Email</td>
                    <td class="p-3 text-left">Joined</td>
                  
                     <td class="p-3 "> Action</td>

                    </tr>
                </thead>

                 <tbody class="text-gray-600 text-xs font-medium">
                
                  
                    <tr v-for="(member, index) in members" :key="member.id" class="border-b mb-10 border-gray-200">
                       <td class="p-3"> {{ index + 1}}  </td>
                    <td class="p-3 flex items-center "> 
                      <img v-if="member.user.profile.image" class="h-8 w-8 mr-2 rounded-lg" :src="getfile(member.user.profile.image)" alt="" srcset="">
                      <img class=" w-10 h-10  object-cover rounded-full" v-else src="@/assets/images/default.png">
                       {{ member.user.name }}</td>
                    <td class="p-3 text-left">{{ member.user.email }}</td>
                    <td class="p-3 text-left">{{ formatDate(member.created_at) }}</td>
                  
                    <td v-if="member.role" class="p-3">
                    <span class="px-6 py-2 bg-indigo-900 rounded-md text-white  " >{{member.role.name}}</span>                    
                    </td>
                    <!-- <td v-if="member.role && authenticated." class="p-3">
                    <span class="px-6 py-2 bg-indigo-900 rounded-md text-white  " >{{member.role.name}}</span>                    
                    </td> -->
                    <td v-if="!member.role" class="p-3 flex items-center space-x-6">
                        <span class="px-6 py-2 bg-red-600 rounded-md text-white cursor-pointer" @click.prevent="suspend(member.user.id)">Suspend</span>

                      <div class="w-full">
                        <Popper  arrow hover >
                        <button> <img src="@/assets/images/3-dots.svg" alt="" srcset=""></button>
                        <template #content ="{ close }">
<!--               
                          <button v-on:click="close" class="hover:text-indigo-500 hover :bg-indigo-300 rounded-md w-full text-sm text-center flex flex-col space-y-6 mb-4" @click.prevent="assignRole(member.user.id, role.name)">
                            Revoke Role
                          </button> -->
                        <div v-for="role in roles" :key="role.id" class="text-sm text-center flex flex-col space-y-6 mb-4">
                          <button v-on:click="close" class="hover:text-indigo-500 hover :bg-indigo-300 rounded-md w-full" @click.prevent="assignRole(member.user.id, role.id)">
                            Assign {{role.name}}
                          </button>
                        
                        </div>
                        
                    </template>
                        </Popper>

                  </div>
               
                      </td>

                  </tr>


 
        
               
                </tbody>
                  </table>
       
                </tab>

                <tab v-if="memberRole.role" class="space-y-8" name="Unapproved Members">
          <table class=" w-full table-auto ">
             <thead >
                  <tr class=" bg-indigo-900 text-white text-xs leading-normal text-wiifarm-500">
                    <td class="p-3"> S/N  </td>
                    <td class="p-3 text-left">Name</td>
                    <td class="p-3 text-left">Email</td>
                    <td class="p-3 text-left">Joined</td>
                  
                     <td class="p-3 "> Action</td>

                    </tr>
                </thead>

                 <tbody class="text-gray-600 text-xs font-medium">
                
                  
                    <tr v-for="(member, index) in unapproved" :key="member.id" class="border-b mb-10 border-gray-200">
                       <td class="p-3"> {{ index + 1}}  </td>
                    <td class="p-3 flex items-center "> <img v-if="member.user.profile.image" class="h-8 w-8 mr-2 rounded-lg" :src="getfile(member.user.profile.image)" alt="" srcset="">
                    <img class=" w-10 h-10  object-cover rounded-full" v-else src="@/assets/images/default.png">
                     {{ member.user.name }} </td>
                    <td class="p-3 text-left">{{ member.user.email }}</td>
                    <td class="p-3 text-left">{{ formatDate(member.created_at) }}</td>
                   
                    <td class="p-3">
                    <button @click.prevent="approve(member.id)" class="px-6 py-2 bg-indigo-900 rounded-md text-white  " >Approve</button>
                  
                      </td>
         

                  </tr>

                 
               
                </tbody>
                  </table>

                </tab>

                <tab v-if="memberRole.role" class="space-y-8" name="Suspended Members">
  <table class=" w-full table-auto ">
             <thead >
                  <tr class=" bg-indigo-900 text-white text-xs leading-normal text-wiifarm-500">
                    <td class="p-3"> S/N  </td>
                    <td class="p-3 text-left">Name</td>
                    <td class="p-3 text-left">Email</td>
                    <td class="p-3 text-left">Joined</td>
                  
                     <td class="p-3 "> Action</td>

                    </tr>
                </thead>

                 <tbody class="text-gray-600 text-xs font-medium">
                
                  
                    <tr v-for="(member, index) in suspended" :key="member.id" class="border-b mb-10 border-gray-200">
                       <td class="p-3"> {{ index + 1}} </td>
                    <td class="p-3 flex items-center "> <img v-if="member.user.profile.image" class="h-8 w-8 mr-2 rounded-lg" :src="getfile(member.user.profile.image)" alt="" srcset=""> 
                    <img class=" w-10 h-10  object-cover rounded-full" v-else src="@/assets/images/default.png">
                    {{ member.user.name }} </td>
                    <td class="p-3 text-left">{{ member.user.email }}</td>
                    <td class="p-3 text-left">{{ formatDate(member.created_at) }}</td>
                   
                    <td class="p-3">
                    <button @click.prevent="unSuspend(member.user.id)" class="px-6 py-2 bg-indigo-900 rounded-md text-white  " >Unsuspend</button>         
                      </td>
         

                  </tr>

                 
               
                </tbody>
                  </table>


                </tab>

            </tabs>
       
       
      
      </div>
  </div>



  
</template>


<script>

    import { mapGetters } from 'vuex'
    import moment from 'moment'

export default {
  name: 'Events',

  components:{
    
  },
  data() {
      return {
          toggle : false,
          group: '',
          members: [],
          suspended: [],
          unapproved: [],
          roles: [],
          form: {
              user_id: '',
              group_id : this.$route.params.id
          },
          user: '',
          memberRole:'',
          url: process.env.VUE_APP_BASE_URL,
      }
  },

computed: {
     ...mapGetters(['authenticated'])     
},

 mounted() {
    this.user =this.authenticated.id
    this.getMembers()
    this.suspendedMembers()
    this.unapprovededMembers()
    this.role()
    this.groupUserRole()
  },

methods:{
 showAlert(message){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: message,
       showConfirmations: false,
       timer: 1500,
        confirmButtonColor: '#312E81'
   });

   setTimeout(() => {
       window.location.reload();
     }, 1600);

    },
    
     formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },

     getfile(media){
      return this.url + `file?file=`+media    
    },

    getMembers() {
      this.axios.get(`group/`+ this.$route.params.id +`/members`)
      .then((response) => {
        this.group = response.data.data 
        this.members = response.data.data.group_users 
      })
      .catch((error) => {
        console.log(error)
      })
    },

   role() {
      this.axios.get(`group/roles`)
      .then((response) => {
        this.roles = response.data.data 
      })
      .catch((error) => {
        console.log(error)
      })
    },

    suspendedMembers() {
      this.axios.get(`group/`+ this.$route.params.id + `/users/suspended`)
      .then((response) => {
        this.suspended = response.data.data 
      })
      .catch((error) => {
        console.log(error)
      })
    },

    suspend(id){
      this.form.user_id = id
      this.axios.post(`group/user/suspend`, this.form)
      .then(() => {        
        this.showAlert('Member Suspended')
      })
      .catch((error) => {
        console.log(error)
      })
    },

    unSuspend(id){
      this.form.user_id = id
      this.axios.post(`group/user/unsuspend`, this.form)
      .then(() => {        
        this.showAlert('Member UnSuspended')
      })
      .catch((error) => {
        console.log(error)
      })
    },

    unapprovededMembers() {
      this.axios.get(`group/`+ this.$route.params.id + `/users/unapproved`)
      .then((response) => {
        this.unapproved = response.data.data 
      })
      .catch((error) => {
        console.log(error)
      })
    },

    approve(id) {
      this.axios.post(`group/user/approve`, {
        group_user_id: id,
        group_id: this.$route.params.id
      })
      .then(() => {        
        this.showAlert('Member Approved')
      })
      .catch((error) => {
        console.log(error)
      })
    },

    assignRole(user,role){
      this.axios.post(`group/user/role/assign`, {
        user_id: user,
        group_id: this.$route.params.id,
        role : role
      })
      .then(() => {        
        window.location.reload();
      })
      .catch((error) => {
        console.log(error)
      })
    },
    groupUserRole(){
      this.axios.get(`group/`+this.$route.params.id+`/user/`+this.user +`/role`)
      .then((response) => {
        this.memberRole = response.data.data 
      })
      .catch((error) => {
        console.log(error)
      })
    }
    }
}
</script>