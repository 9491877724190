<template>
<div class="mt-10">
    <div class="bg-white w-full md:w-3/4 md:mx-auto  rounded-t-3xl p-8 space-y-8 shadow-md mb-20">

            <tabs :options="{ useUrlFragment: false }" >

                <tab class="space-y-8 overflow-x-auto" name="All Friends" >
         <div class="space-y-4">
                <table class=" w-full table-auto ">
             <thead >
                  <tr class=" bg-indigo-900 text-white text-xs leading-normal text-wiifarm-500">
                    <td class="p-3"> S/N  </td>
                    <td class="p-3 text-left">Image</td>
                    <td class="p-3 text-left">Username</td>
                    <td class="p-3 text-left">Phone Number</td>
                    <td class="p-3 text-left">Marital Status</td>
                     <td class="p-3 "> Actions</td>

                    </tr>
                </thead>

                 <tbody class="text-gray-600 text-xs font-medium">
                
                <AllFriends />
 
        
               
                </tbody>
                  </table>
       
       </div>

       
                </tab>





                <tab class="space-y-8 overflow-x-auto" name="Add Friends ">
               <table class=" w-full table-auto ">
             <thead >
                  <tr class=" bg-indigo-900 text-white text-xs leading-normal text-wiifarm-500">
                    <td class="p-3"> S/N  </td>
                    <td class="p-3 text-left">Image</td>
                    <td class="p-3 text-left">Username</td>
                    <td class="p-3 text-left">Phone Number</td>
                    <td class="p-3 text-left">Marital Status</td>
                     <td class="p-3 "> Actions</td>

                    </tr>
                </thead>

                 <tbody class="text-gray-600 text-xs font-medium">
                
                <AddFriend />
 
        
               
                </tbody>
                  </table>
       
                </tab>


                <tab class="space-y-8 overflow-x-auto" name="Friend Requests">
                 <table class=" w-full table-auto ">
             <thead >
                  <tr class=" bg-indigo-900 text-white text-xs leading-normal text-wiifarm-500">
                    <td class="p-3"> S/N  </td>
                    <td class="p-3 text-left">Image</td>
                    <td class="p-3 text-left">Username</td>
                    <td class="p-3 text-left">Phone Number</td>
                    <td class="p-3 text-left">Marital Status</td>
                     <td class="p-3 "> Actions</td>

                    </tr>
                </thead>

                 <tbody class="text-gray-600 text-xs font-medium">
                
               
                  <FriendRequest />
 
        
               
                </tbody>
                  </table>

                </tab>


            <tab class="space-y-8 overflow-x-auto" name="Pending Friend Requests">
             <table class=" w-full table-auto ">
             <thead >
                  <tr class=" bg-indigo-900 text-white text-xs leading-normal text-wiifarm-500">
                    <td class="p-3"> S/N  </td>
                    <td class="p-3 text-left">Image</td>
                    <td class="p-3 text-left">Username</td>
                    <td class="p-3 text-left">Phone Number</td>
                    <td class="p-3 text-left">Marital Status</td>
                     <td class="p-3 "> Status</td>

                    </tr>
                </thead>

                 <tbody class="text-gray-600 text-xs font-medium">
                
                  <PendingFriendRequest />
 
        
               
                </tbody>
                  </table>

                </tab>

            </tabs>
       
       
      
      </div>
  </div>
    
</template>



<script>
import AllFriends from '../components/AllFriends.vue'
import AddFriend from '../components/AddFriend.vue'
import FriendRequest from '../components/FriendRequest.vue'
import PendingFriendRequest from '../components/PendingFriendRequest.vue'
export default {
    name: 'Friends',

    components: {
        AllFriends,
        AddFriend,
        FriendRequest,
        PendingFriendRequest
    }
}

</script>