<template>
   
      
                    <tr v-for="(friend, index) in friends" :key="friend.id" class="border-b mb-10 border-gray-200">
                       <td class="p-3"> {{ index + 1}} </td>
                    <td class="p-3 flex items-center ">  
                      <img v-if="friend.friend.profile.image" class="h-8 w-8 mr-2 rounded-lg" :src="getfile(friend.friend.profile.image)" alt="" srcset="">
                  <img v-else class="h-8 w-8 mr-2 rounded-lg" src="@/assets/images/default.png" alt="" srcset="">
                      </td>
                    <td class="p-3 text-left">{{ friend.friend.name }} </td>
                    <td class="p-3 text-left">{{ friend.friend.profile.phone ? friend.friend.profile.phone : 'Rather not say' }}</td>
                    <td class="p-3 text-left">{{ friend.friend.profile.marital_status ? friend.friend.profile.marital_status : 'Rather not say' }}</td>
                   



                    <td class="p-3">
                        <div class="w-full">
                <Popper  arrow hover>
                <button>    <img src="@/assets/images/3-dots.svg" alt="" srcset=""></button>
                <template #content="{ close }">
      
                    <div class="text-sm text-center flex flex-col space-y-3">
                 <a href="#" v-on:click="close" @click.prevent="showCancelAlert(friend.id)" class="hover:text-indigo-500 w-full">UnFriend 😔</a>
              <router-link :to="{name: 'FriendProfile', params: {id: friend.friend.id}}" class="hover:text-indigo-500 w-full">View Profile</router-link>
                
                    </div>
                
            </template>
                </Popper>

               </div>
                      </td>

                  </tr>








</template>



<script>

import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'AllFriends',

  data() {
    return {
    toggle : false,
     friends: [],
      url: process.env.VUE_APP_BASE_URL,
      authenticatedUser: '',
    }

},

 computed: {
     ...mapGetters(['authenticated'])     
},

 mounted() {
    this.authenticatedUser = this.authenticated
    this.getFriends()
},

  methods:{
        showCancelAlert(id){
      this.$swal({
         position: 'top-end',
         title:'Are you sure you want to unfriend user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#312E81',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Unfriend!',
      }).then((result) => {
          if (result.isConfirmed) {
            this.unfriend(id)
              
          }
      })
  },

  successUnfriend(){
     this.$swal(
          'Deleted!',
          'Friend has been removed! ',
          'Success'
      ),

      setTimeout(() => {
       window.location.reload();
     }, 1600);

  },

    formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },
    getfile(media){
      return this.url + `file?file=`+media    
    },

    getFriends() {
      this.axios.get(`friends`)
      .then((response) => {
        this.friends = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    unfriend(id){
      this.axios.delete(`friends/`+id)
      .then(() => {        
        this.successUnfriend()
      })
      .catch((error) => {
        console.log(error)
      })
    },
   
  },

}


</script>