<template>
  <div id="Profile">
    <main class="my-10">
      <div class="w-3/5 mx-auto mb-20">
        <div class="relative">
          <div class="bg-gray-200 rounded-t-xl h-64">
            <div class="profile-image" v-if="!preview">
              <div v-if="authenticatedUser">
                <img
                  v-if="authenticatedUser.profile.image"
                  :src="profilePic(authenticatedUser.profile.image)"
                  alt="jpg"
                  class="h-64 w-full bg-cover rounded-t-xl"
                  srcset=""
                />

                <img
                  v-else-if="
                    authenticatedUser.profile.image == '' ||
                    !authenticatedUser.profile.image
                  "
                  alt="image"
                  class="h-64 w-full bg-cover rounded-t-xl"
                  src="@/assets/images/default.png"
                  srcset=""
                />
              </div>
            </div>
            <div class="profile-image" v-if="preview">
              <img
                :src="preview"
                alt="jpg"
                class="h-64 w-full bg-cover rounded-t-xl"
                srcset=""
              />
            </div>
          </div>

          <div class="absolute top-3 left-2 lg:right-5">
            <span
              class="
                w-12
                h-12
                relative
                rounded-full
                bg-white
                flex flex-col
                items-center
                justify-center
              "
            >
              <img
                class="absolute"
                src="@/assets/images/camera.svg"
                alt=""
                srcset=""
              />

              <input
                @change="uploadPics"
                class="opacity-0"
                type="file"
                name=""
                id=""
              />
            </span>
          </div>
        </div>

        <div class="bg-white relative p-10 space-y-8 rounded-b-xl mb-4">
          <!-- <div class="profile-image">
              <img class="w-28 h-28 absolute -top-12  rounded-full p-1 bg-white" src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" alt="" srcset="">
              </div> -->
          <div class="profile-image" v-if="!preview">
            <div v-if="authenticatedUser">
              <img
                v-if="authenticatedUser.profile.image"
                :src="profilePic(authenticatedUser.profile.image)"
                alt="jpg"
                class="w-28 h-28 absolute -top-12 rounded-full p-1 bg-white"
                srcset=""
              />

              <img
                v-else-if="
                  authenticatedUser.profile.image == '' ||
                  !authenticatedUser.profile.image
                "
                alt="image"
                src="@/assets/images/default.png"
                class="w-28 h-28 absolute -top-12 rounded-full p-1 bg-white"
                srcset=""
              />
            </div>
          </div>
          <div class="profile-image" v-if="preview">
            <img
              :src="preview"
              alt="jpg"
              class="w-28 h-28 absolute -top-12 rounded-full p-1 bg-white"
              srcset=""
            />
          </div>

          <h1 class="text-lg">{{ authenticatedUser.name }}</h1>

          <form
            method="POST"
            @submit.prevent="update"
            class="grid grid-cols-1 md:grid-cols-2 text-xs overflow-hidden"
            action=""
          >
            <div class="flex flex-col space-y-4 p-2">
              <div
                class="text-md"
                style="overflow-wrap: break-word"
                :class="{ error: v$.form.name.$errors.length }"
              >
                <label class="font-bold" for="">Name: </label>
                <span v-show="!showField('name')" @click="focusField('name')">{{
                  authenticatedUser.name
                }}</span>

                <input
                  class="px-2 py-1 rounded-md"
                  v-model="v$.form.name.$model"
                  v-show="showField('name')"
                  id="name"
                  type="text"
                  @focus="focusField('name')"
                  @blur="blurField"
                  placeholder=" Edit Your Name"
                />
                <div
                  class="input-errors"
                  v-for="(error, index) of v$.form.name.$errors"
                  :key="index"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">Email: </label>
                <span
                  v-show="!showField('email')"
                  @click="focusField('email')"
                  >{{ authenticatedUser.email }}</span
                >

                <input
                  class="px-2 py-1 rounded-md"
                  readonly
                  v-model="form.email"
                  v-show="showField('email')"
                  id="email"
                  type="email"
                  @focus="focusField('email')"
                  @blur="blurField"
                  placeholder=" Add your email address"
                />
              </div>

              <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">Phone No: </label>
                <span
                  v-show="!showField('phone')"
                  @click="focusField('phone')"
                  >{{ form.phone }}</span
                >

                <input
                  class="px-2 py-1 rounded-md"
                  v-model="form.phone"
                  v-show="showField('phone')"
                  id="phone"
                  type="phone"
                  @focus="focusField('phone')"
                  @blur="blurField"
                  placeholder=" Add Phone Number"
                />
              </div>

              <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">Country: </label>

                <select v-model="form.country" v-on:change="state(form.country)" style="width:210px;" class=" border focus:ring-indigo-700 py-1 rounded-md"
                  id="country">
                <option v-for="(country, index) in countries" :key="index" :value="country">{{country}}</option>
                </select>
              </div>

              <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">State: </label>
                <!-- <span
                  v-show="!showField('state')"
                  @click="focusField('state')"
                  >{{ form.state }}</span
                > -->

                <select v-model="form.state" class=" border focus:ring-indigo-700  py-1 px-6 rounded-md"
                  id="state">
                <option v-for="(state, index) in staties" :key="index" :value="state">{{state}}</option>
                </select>
              </div>
            </div>

            <div class="flex flex-col space-y-4 p-2">
              <!-- <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">City: </label>

                 <select v-model="form.city" class=" border focus:ring-indigo-700  py-1 px-6 rounded-md"
                  id="city">
                <option v-for="(city, index) in cities" :key="index" :value="city">{{city}}</option>
                </select>
              </div> -->

              <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">Marital Status: </label>

                 <select v-model="form.marital_status" class=" border focus:ring-indigo-700  py-1 px-6 rounded-md"
                  id="marital-status">
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
                <option value="Complicated">Complicated</option>
                </select>
              </div>

                <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">Gender: </label>

                 <select v-model="form.gender" class=" border focus:ring-indigo-700  py-1 px-6 rounded-md"
                  id="marital-status">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                </select>
              </div>

              <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">Hobbies: </label>
                <span
                  v-show="!showField('hobbies')"
                  @click="focusField('hobbies')"
                  >{{ form.hobbies }}</span
                >

                <input
                  class="px-2 py-1 rounded-md"
                  v-model="form.hobbies"
                  v-show="showField('hobbies')"
                  id="state"
                  type="text"
                  @focus="focusField('hobbies')"
                  @blur="blurField"
                  placeholder=" Add Your Hobbies"
                />
              </div>

              <div class="text-md" style="overflow-wrap: break-word">
                <label class="font-bold" for="">Bio: </label>
                <span v-show="!showField('bio')" @click="focusField('bio')">{{
                  form.bio
                }}</span>

                <input
                  class="px-2 py-1 rounded-md"
                  v-model="form.bio"
                  v-show="showField('bio')"
                  id="bio"
                  type="text"
                  @focus="focusField('bio')"
                  @blur="blurField"
                  placeholder=" Edit bio"
                />
              </div>

              <div class="flex justify-end pt-5">
                <button
                  type="submit"
                  :disabled="v$.form.$invalid"
                  class="bg-indigo-900 py-3 px-6 rounded-md text-white"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="p-3 rounded-xl bg-white space-y-6">
          <h1 class="text-xs italic text-gray-500">
            The following information can be seen by only you
          </h1>

          <div class="grid grid-cols-1 gap-1 lg:grid-cols-3">
            <div
              class="
                bg-indigo-100
                rounded-md
                text-indigo-900 text-left
                flex flex-col
                text-xs
                p-2
              "
            >
              <span class="text-xl">{{ groups.length }}</span>
              <span>Groups you joined </span>
            </div>

            <div
              class="
                bg-indigo-100
                rounded-md
                text-indigo-900 text-left
                flex flex-col
                text-xs
                p-2
              "
            >
              <span class="text-xl">{{ friends.length }}</span>
              <span>Friends you have </span>
            </div>

            <div
              class="
                bg-indigo-100
                rounded-md
                text-indigo-900 text-left
                flex flex-col
                text-xs
                p-2
              "
            >
              <span class="text-xl">{{ Posts.length }}</span>
              <span>Posts you made</span>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>


<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "Profile",

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      authenticatedUser: "",
      profile: [],
      form: {
        name: "",
        email: "",
        country: "",
        state: "",
        city: "",
        gender:"",
        phone: "",
        marital_status: "",
        bio: "",
        hobbies: "",
        user_id: "",
        image: "",
        profile_id: "",
      },
      url: process.env.VUE_APP_BASE_URL,
      preview: "",
      Posts: [],
      groups: [],
      friends: [],
      countries: [],
      staties: [],
      cities: [],
      editField: "",
      user: "",
    };
  },

  validations: {
    form: {
      name: { required },
      email: { required, email },
    },
  },

  computed: {
    ...mapGetters(["authenticated"]),
  },

  mounted() {
    this.authenticatedUser = this.authenticated;
    this.country();
    this.posts();
    this.group();
    this.friend();
    this.authUser();
  },

  methods: {
    showAlert() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Profile Updated",
        showConfirmations: false,
        timer: 1500,
        confirmButtonColor: "#312E81",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1600);
    },

    focusField(name) {
      this.editField = name;
    },
    blurField() {
      this.editField = "";
    },
    showField(name) {
      return this.user[name] == "" || this.editField == name;
    },

    formatDate(value) {
      if (value) {
        return moment(value).fromNow();
      }
    },

    authUser() {
      //this values are null when empty so do if ==null
      this.form.name = this.authenticatedUser.name;
      this.form.email = this.authenticatedUser.email;
      this.form.user_id = this.authenticatedUser.id;
      this.form.country =
        this.authenticatedUser.profile.country === null
          ? "Add"
          : this.authenticatedUser.profile.country;
      this.form.state = this.authenticatedUser.profile.state
        ? this.authenticatedUser.profile.state
        : "Add";
      this.form.city = this.authenticatedUser.profile.city
        ? this.authenticatedUser.profile.city
        : "Add";
      this.form.bio = this.authenticatedUser.profile.bio
        ? this.authenticatedUser.profile.bio
        : "Add";
      this.form.hobbies = this.authenticatedUser.profile.hobbies
        ? this.authenticatedUser.profile.hobbies
        : "Add";
      this.form.marital_status = this.authenticatedUser.profile.marital_status
        ? this.authenticatedUser.profile.marital_status
        : "Add";
      this.form.phone = this.authenticatedUser.profile.phone
        ? this.authenticatedUser.profile.phone
        : "Add";
      this.form.gender = this.authenticatedUser.profile.gender
        ? this.authenticatedUser.profile.gender
        : "Add";
      this.form.pics = this.authenticatedUser.profile.image;
      this.form.profile_id = this.authenticatedUser.profile.id;
    },

    profilePic(media) {
      return this.url + `file?file=` + media;
    },

    uploadPics(event) {
      this.form.image = event.target.files[0];
      this.preview = URL.createObjectURL(this.form.image);
    },

    update() {
      const formData = new FormData();

      formData.append("name", this.form.name);
      formData.append("email", this.form.email);
      formData.append(
        "phone",
        this.form.phone === "Add" ? "" : this.form.phone
      );
      formData.append(
        "country",
        this.form.country === "Add" ? "" : this.form.country
      );
      formData.append(
        "state",
        this.form.state === "Add" ? "" : this.form.state
      );
      formData.append("city", this.form.city === "Add" ? "" : this.form.city);
      formData.append("bio", this.form.bio === "Add" ? "" : this.form.bio);
      formData.append("gender", this.form.gender === "Add" ? "" : this.form.gender);
      formData.append(
        "hobbies",
        this.form.hobbies === "Add" ? "" : this.form.hobbies
      );
      formData.append("pics", this.form.image ? this.form.image : "");
      formData.append("user_id", this.form.user_id);
      formData.append(
        "marital_status",
        this.form.marital_status === "Add" ? "" : this.form.marital_status
      );
      formData.append("profile_id", this.form.profile_id);
      formData.append("_method", "PUT");

      const config = { headers: { "content-type": "multipart/form-data" } };

      this.axios
        .post(`users/` + this.form.user_id, formData, config)
        .then((response) => {
          this.$store.commit("updateUser", response.data.data);
          this.showAlert();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    posts() {
      this.axios
        .get(`posts`)
        .then((response) => {
          this.Posts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    group() {
      this.axios
        .get(`user/groups`)
        .then((response) => {
          this.groups = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    friend() {
      this.axios
        .get(`friends`)
        .then((response) => {
          this.friends = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    country() {
      this.axios
        .get(`countries`)
        .then((response) => {
          this.countries = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    state(name) {
      this.axios
        .get(`country/` + name + `/states`)
        .then((response) => {
          this.staties = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    city(name) {
      this.axios
        .get(`country/` + name + `/cites`)
        .then((response) => {
          this.cities = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>