import { createStore } from "vuex";
import axios from "@/axios";

const state = {
    user: JSON.parse(sessionStorage.getItem('user')) || null, 
    role: JSON.parse(sessionStorage.getItem('role')) || null, 
    token: sessionStorage.getItem('token') || null
}

const getters = {
    authenticated: state => {
          return state.user;
      },
    token: state => {
      return state.token;
    },
    role: state => {
      return state.role;
    }
  } 

const mutations= {
    setUser (state, payload) {
        state.user = payload.user
        state.role = payload.role
        state.token = payload.access_token
        sessionStorage.setItem('user', JSON.stringify(payload.user))
        sessionStorage.setItem('role', JSON.stringify(payload.role))
        sessionStorage.setItem('token',  payload.access_token)
    },
    logout(state) {
      state.user = '',
      state.role = '',
      state.token = '',
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('role')
      sessionStorage.removeItem('token')
    },
    updateUser (state, payload) {
      // state.user = '',
      // sessionStorage.removeItem('user')
      state.user = payload
      sessionStorage.setItem('user', JSON.stringify(payload))
  },
}

const actions = {
    retrieveUser( {context}, payload) {
      axios.post(`login`, {
        email: payload.email,
        password: payload.password
      })
      .then((response) => {
        context.commit('setUser', response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
    }
}

export default createStore({
    state,
    getters,
    mutations,
    actions,
})