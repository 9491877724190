import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/auth/Login.vue'
import SignUp from '../views/auth/SignUp.vue'
import ForgotPassword from '../views/auth/ForgotPassword'
import ChangePassword from '../views/auth/ChangePassword'
import Groups from '../views/Groups.vue'
import Events from '../views/Events.vue'
import Timeline from '../views/Timeline.vue'
import Counselling from '../views/Counselling.vue'
import Profile from '../views/Profile.vue'
import Eventdetails from '../views/Event-details.vue'
import Eventregistereddetails from '../views/Event-registered-details.vue'
import Friends from '../views/Friends.vue'
import Grouptimeline from '../views/Grouptimeline.vue'
import EditGroup from '../views/EditGroup.vue'
import MyGroupPosts from '../views/MyGroupPosts.vue'
import GroupMembers from '../views/GroupMembers.vue'
import InviteMembers from '../views/InviteMembers.vue'
import TimelineComment from '../views/TimelineComment.vue'
import GroupComment from '../views/GroupComment.vue'
import Comment from '../views/Comment.vue'
import FriendProfile from '../views/FriendProfile.vue'
import store from "../store/index"

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {hideHeader: true, hideFooter: true},
   
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: SignUp,
    meta: {hideHeader: true, hideFooter: true},
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {hideHeader: true, hideFooter: true},
  },
  {
    path: '/reset/password/:id',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {hideHeader: true, hideFooter: true},
  },
  {
    path: '/Groups',
    name: 'Groups',
    component: Groups,
    meta: { auth: true }
  },
  {
    path: '/Timeline',
    name: 'Timeline',
    component: Timeline,
    meta: { auth: true }
  },
  {
    path: '/Events',
    name: 'Events',
    component: Events,
    meta: { auth: true }
  },
  {
    path:'/Event/:id/details',
    name:'Event-details',
    component: Eventdetails,
    meta: { auth: true }
  },
  {
    path:'/Event-registered/:id/details',
    name:'Event-registered',
    component: Eventregistereddetails,
    meta: { auth: true }
  },
  {
    path: '/Counselling',
    name: 'Counselling',
    component: Counselling 
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    meta: { auth: true }
  },
  {
    path: '/Friends',
    name: 'Friends',
    component: Friends,
    meta: { auth: true }
  },
  {
    path:'/Grouptimeline/:id/posts',
    name: 'Grouptimeline',
    component: Grouptimeline,
    meta: { auth: true }
  },
  {
    path:'/Group/:id/edit',
    name: 'EditGroup',
    component: EditGroup,
    meta: { auth: true }
  },
  {
    path:'/Group/:id/posts',
    name: 'MyGroupPosts',
    component: MyGroupPosts,
    meta: { auth: true }
  },
  {
    path:'/Group/:id/members',
    name: 'GroupMembers',
    component: GroupMembers,
    meta: { auth: true }
  },
  {
    path:'/Invite:id/members',
    name: 'InviteMembers',
    component: InviteMembers,
    meta: { auth: true }
  },
  {
    path:'/TimelineComment/:id/comments',
    name: 'TimelineComment',
    component: TimelineComment,
    meta: { auth: true }
  },
  {
    path:'/Group/:id/comments',
    name: 'GroupComment',
    component: GroupComment,
    meta: { auth: true }
  },
  {
    path:'/comment/:id/comments',
    name: 'Comment',
    component: Comment,
    meta: { auth: true }
  },
  {
    path:'/friends/:id/profile',
    name:'FriendProfile',
    component: FriendProfile,
    meta: { auth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)){
      if(!store.getters.authenticated){
          next({
              name: Login
          });
      }else {
          next();
      }
  }
  else {
      next();
  }
})

export default router
