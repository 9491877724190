<template>
    <div id="event-details">
  <main class=" m-10">
 

      <div class="w-8/12 mx-auto rounded-md p-8 space-y-4 ">
        <router-link class="hover:text-indigo-500" to="/Groups">
         <button class="text-xs flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
</svg>

<span>Back</span>
          </button>
        
        </router-link>   
        
           

        <div class="rounded-lg">
           
            <div class="flex-1 bg-white text-sm space-y-2 p-6 rounded-lg shadow-lg border">
                 <h1 class="text-3xl pb-4 text-indigo-900">Edit Group</h1>
                <form class="w-full lg:px-10" method="POST" @submit.prevent="update">
  <div class="flex flex-wrap mb-6">
    <div class="w-full mb-6 md:mb-0" :class="{ error: v$.form.name.$errors.length }">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="firstname">
       Group Name
      </label>
      <input v-model="v$.form.name.$model" id="name" class="appearance-none block w-full  text-gray-700 shadow-sm  border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight text-xs focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1"  type="text" placeholder="John">
      <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
          <div class="error-msg text-red-500 text-xs italic">{{ error.$message }}</div>
        </div>
    </div>

  </div>


   <div class="flex flex-wrap mb-6">
    <div class="w-full mb-6 md:mb-0" :class="{ error: v$.form.description.$errors.length }">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="firstname">
       Group Description
      </label>
  <textarea rows="4" v-model="v$.form.description.$model" cols="50" class=" block w-full  text-gray-700 shadow-sm  border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight text-xs focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1"  type="" placeholder="description about the group..."></textarea>
     <div class="input-errors" v-for="(error, index) of v$.form.description.$errors" :key="index">
              <div class="error-msg text-red-500 text-xs italic">{{ error.$message }}</div>
            </div>
    </div>

  </div>


   <div class="flex flex-wrap mb-4">
        <div class="w-full mb-6 md:mb-0">
          <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
          Group Image
          </label>
          <input @change="uploadPics" class=" block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  type="file" placeholder="">
         
        </div>

      </div>

       <div class="flex flex-wrap mb-4">
        <div class="w-full mb-6 md:mb-0 text-xs text-center italic">
  <span>if Visible checked, anyone can see the group and request to join, else members can only be added by members of the group</span>
        </div>
        </div>



       <div class="flex flex-wrap mb-6">
        <div class="flex items-center jus md:w-1/2 mb-6 md:mb-0 space-x-2">
          <label class="block tracking-wide text-gray-700 text-xs font-bold" for="">
          Public 
          </label>
         <input type="checkbox" v-model="form.public" value={{value}} name="" id="">
         
        </div>
         <div v-if="!form.public" class="flex justify-end items-center md:w-1/2 mb-6 md:mb-0 space-x-2">

          
  <label class="block tracking-wide text-gray-700 text-xs font-bold" for="">
          Visible
          </label>
         <input type="checkbox" v-model="form.visible" name="" id="">
        
        </div> 

      </div>


          <div class="flex flex-wrap mb-4">
        <div class="w-full md:w-1/2  mb-6 md:mb-0" :class="{ error: v$.form.category_id.$errors.length }">
            <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
            Category
            </label>
            <select v-model="v$.form.category_id.$model" id="cat" v-on:change="sortBy" class=" block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  >
                <option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</option>
              
            </select>
           <div class="input-errors" v-for="(error, index) of v$.form.category_id.$errors" :key="index">
              <div class="error-msg text-red-500 text-xs italic">{{ error.$message }}</div>
          </div>
          </div>
    
       <div v-if="selectedCat" class="w-full md:w-1/2  pl-3 mb-6 md:mb-0">
            <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
            Streams
            </label>
            <select v-model="form.stream_id" class=" block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs">
                <option v-for="stream in streams" :key="stream.id" :value="stream.id">{{stream.name}}</option>
            </select>
          </div>

          </div>

  <div class="flex flex-wrap mb-6">
        <div class=" w-full mb-6 md:mb-0 " :class="{ error: v$.form.max_members.$errors.length }">
          <label class="block tracking-wide mb-2 text-gray-700 text-xs font-bold" for="">
        Maximum Members
          </label>
           <input v-model="v$.form.max_members.$model" class=" block w-full appearance-none  text-gray-700 hadow-sm  border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  type="number" placeholder="">
        </div>
        <div class="input-errors" v-for="(error, index) of v$.form.max_members.$errors" :key="index">
          <div class="error-msg text-red-500 text-xs italic">{{ error.$message }}</div>
        </div>

      </div>          

  <div class="flex justify-center mb-4">
<button type="submit" :disabled="v$.form.$invalid" class=" bg-indigo-900 py-3 w-full rounded-md text-white">Update</button>
  </div>




          </form>

            </div>
        </div>
         
      </div>
    </main>
    </div>
</template>


<script>

    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import { mapGetters } from 'vuex'

export default {
    name: 'EditGroup',

computed: {
    ...mapGetters(['authenticated'])     
},

mounted() {
   this.form.created_by = this.authenticated.id
   this.getGroup()
   this.category()
   this.stream()
  },

setup () {
  return { v$: useVuelidate() }
  },

  data() {
    return {
      form:{
        name: '',
        description: '',
        public: false,
        visible: false,
        max_members: '',
        created_by: '',
        category_id:'',
        stream_id:'',
        image: '',
        pics:''
      } ,
      value: true,
      categories: [],
      streams: [],
      SelCat:'',
      stre:false
    }
  },

  validations: {
    form: {
        name: { required },
        description: { required },
        max_members: { required },
        category_id: { required },
      }
  },

  methods:{
 showAlert(){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: 'Group Edited Successfully',
       showConfirmations: false,
       timer: 1500,
        confirmButtonColor: '#312E81'
   });

    setTimeout(() => {
       this.$router.push('/Groups')
     }, 1600);

      },

uploadPics(event) {
      this.form.pics = event.target.files[0] 
    },

      getGroup() {
      this.axios.get(`groups/`+ this.$route.params.id)
      .then((response) => {
        this.form = response.data.data
        this.sortBy()
      })
      .catch((error) => {
        console.log(error)
      })
    },

    update() {
      if(this.SelCat === 'OTHERS' && this.form.stream_id == ''){
        this.stre=true
        return ;
      }

      if(this.form.public == true){
        this.form.visible = true
      }

      const formData  = new FormData()
      formData.append('name', this.form.name);
      formData.append('description', this.form.description);
      formData.append('public', this.form.public);
      formData.append('visible', this.form.visible);
      formData.append('max_members', this.form.max_members);
      formData.append('created_by', this.form.created_by);
      formData.append('category_id', this.form.category_id);
      formData.append('stream_id', this.form.stream_id);
      formData.append('pics', this.form.pics ? this.form.pics : '');
      formData.append('_method', 'PUT');

      const config = { headers: { 'content-type': 'multipart/form-data' } }

      this.axios.post(`groups/`+ this.$route.params.id, formData, config)
        .then(() => {  
           this.showAlert()   
        })
        .catch((error) => {
          console.log(error)
        })
    },

  category() {
    this.axios.get(`categories`)
    .then((response) => {
      this.categories = response.data.data
    })
    .catch((error) => {
      console.log(error)
    })
  },

  stream() {
    this.axios.get(`streams`)
    .then((response) => {
      this.streams = response.data.data
    })
    .catch((error) => {
      console.log(error)
    })
  },

    sortBy: function() {
      var cat = document.getElementById("cat");
      this.SelCat = cat.options[cat.selectedIndex].text;

      if(this.SelCat === 'OTHERS' ){
         this.selectedCat =true
      }else{
        this.selectedCat = false
      }

    },
    }
}
</script>