<template>
  <div class="timeline">
    <div class="main grid md:grid-cols-4 gap-4 md:mx-4 mb-20">
      <div class="left-sidebar space-y-4 p-2">
        <div
          class="
            user-profile
            items-center
            p-8
            bg-white
            flex flex-col
            justify-center
            space-y-4
            rounded-xl
            shadow-md
          "
        >
          <img
            class="w-10 h-10 object-cover rounded-full"
            v-if="authenticated.profile.image"
            :src="getfile(authenticated.profile.image)"
          />
          <img
            class="w-10 h-10 object-cover rounded-full"
            v-else
            src="@/assets/images/default.png"
          />

          <span class="text-xs font-serif">{{ authenticated.name }}</span>
          <span v-if="authenticated.profile.bio" class="text-xs">{{
            authenticated.profile.bio
          }}</span>
          <span v-if="authenticated.profile.country" class="text-xs"
            >Country: {{ authenticated.profile.country }} <br />
            State:
            {{
              authenticated.profile.state ? authenticated.profile.state : ""
            }}</span
          >
        </div>

        <div
          class="
            Groups
            p-3
            space-y-4
            bg-white
            flex flex-col
            rounded-xl
            shadow-md
          "
        >
          <div class="p-2">
            <span>Discover</span>
          </div>

          <span v-if="groups.length === 0"><em> No groups yet...</em></span>

          <div v-else class="space-y-4">
            <div
              v-for="group in groups.slice(0, 2)"
              :key="group.id"
              class="
                space-y-4
                pt-2
                border
                flex flex-col
                items-center
                rounded-md
                shadow-md
                bg-white
              "
            >
              <img
                v-if="group.image"
                class="rounded-full w-24 h-24"
                :src="getfile(group.image)"
                alt=""
                srcset=""
              />
              <img
                v-else
                class="rounded-full w-24 h-24"
                src="@/assets/images/group.png"
                alt=""
                srcset=""
              />

              <div
                class="
                  flex flex-col
                  items-center
                  bg-gray-200
                  space-y-4
                  p-3
                  w-full
                "
              >
                <h1 class="text-gray-600">{{ group.name }}</h1>

                <span class="text-xs text-gray-600"
                  >{{ group.group_members.length }} members
                </span>

                <button
                  v-if="group.public === true"
                  @click.prevent="joinPublic(group.id)"
                  class="w-full text-white rounded-xl bg-indigo-900 py-2"
                >
                  Join
                </button>
                <button
                  v-else
                  @click.prevent="joinPrivate(group.id)"
                  class="w-full text-white rounded-xl bg-indigo-900 py-2"
                >
                  Request To Join
                </button>
              </div>
            </div>
            <div class="w-full">
              <hr />
            </div>

            <button
              @click.prevent="discover()"
              class="
                p-2
                hover:underline hover:text-indigo-900
                text-center
                w-full
              "
            >
              Discover More
            </button>
          </div>
        </div>

        <div
          class="
            Friends
            p-3
            bg-white
            flex flex-col
            rounded-xl
            shadow-md
            space-y-4
          "
        >
          <div class="pb-4">
            <span>Friends</span>
          </div>

          <span v-if="nonFriends.length === 0"><em>No Users yet</em></span>

          <div v-else class="space-y-4">
            <div
              v-for="friend in nonFriends.slice(0, 3)"
              :key="friend.id"
              class="flex items-center text-md"
            >
              <div class="flex items-center space-x-6">
                <img
                  v-if="friend.profile.image"
                  class="rounded-full w-8 h-8"
                  :src="getfile(friend.profile.image)"
                  alt=""
                  srcset=""
                />
                <img
                  v-else
                  class="rounded-full w-10 h-10"
                  src="@/assets/images/default.png"
                  alt=""
                  srcset=""
                />
                <span class="text-xs">{{ friend.name }} </span>
              </div>

              <a
                href="#"
                @click.prevent="add(friend.id)"
                class="
                  text-sm
                  border border-indigo-900
                  rounded-lg
                  mr-2
                  py-2
                  px-4
                  text-indigo-900
                  hover:bg-indigo-900 hover:text-white hover:border-indigo-900
                  cursor-pointer
                  ml-auto
                "
                >Add</a
              >
            </div>

            <div class="w-full">
              <hr />
            </div>

            <a
              class="
                p-2
                hover:underline hover:text-indigo-900
                flex
                justify-center
              "
              href="/Friends"
              >More Friends</a
            >
            <!-- <router-link :to="{name: 'Comment', params: {id: comment.id}}">{{comment.content}}</router-link> -->
          </div>
        </div>
      </div>

      <div
        class="
          main-content
          p-2
          space-y-4
          md:col-span-2
          order-first
          md:order-none
        "
      >
        <CreatePost />

        <!----- User TimeLine Section -->
        <div
          class="timeline-feeds items-center space-y-8 h-screen overflow-y-auto"
        >
          <TimelineFeeds />
        </div>
        <!----- User TimeLine Section End-->
      </div>

      <div class="right-sidebar p-2 space-y-4">
        <h1 class="text-center p-4 text-2xl font-bold">Upcoming Events</h1>
        <div v-if="events.length === 0">
          <span><em> No events yet ...</em></span>
        </div>

        <div v-else class="space-y-4">
          <div
            v-for="event in events.slice(0, 4)"
            :key="event.id"
            class="
              event-card
              p-4
              items-center
              bg-white
              grid
              md:grid-cols-1
              rounded-xl
              shadow-md
            "
          >
            <div class="bg-red-500 text-white p-4 rounded-xl">
              <span class="text-lg">{{ event.start_date }} </span>
            </div>

            <div class="p-4 space-y-2">
              <div>
                <h1 class="text-xl text-indigo-900">{{ event.title }}</h1>
                <p class="text-xs text-gray-400">{{ event.details }}.</p>
              </div>

              <div class="details space-y-1">
                <span class="text-xs text-indigo-900 font-bold">Details</span>
                <div class="flex items-center text-xs space-x-2">
                  <img src="@/assets/images/task.svg" alt="" srcset="" />
                  <span>Venue: {{ event.location }}</span>
                </div>

                <div class="flex items-center text-xs space-x-2">
                  <img src="@/assets/images/calendar.svg" alt="" srcset="" />
                  <span>{{ event.start_date }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="button flex justify-center">
            <button
              @click.prevent="event()"
              class="
                bg-indigo-900
                px-6
                py-2
                rounded-xl
                text-white
                hover:bg-indigo-800
              "
            >
              View More
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreatePost from "../components/CreatePost.vue";
import TimelineFeeds from "../components/TimelineFeeds.vue";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    CreatePost,
    TimelineFeeds,
  },

  data() {
    return {
      Options: false,
      events: [],
      groups: [],
      url: process.env.VUE_APP_BASE_URL,
      form: {
        created_by: "",
      },
      allNonFriends: [],
      nonFriends: [],
    };
  },

  computed: {
    ...mapGetters(["authenticated", "role"]),
  },

  mounted() {
    this.getEvents();
    this.suggestedGroups();
    this.getNonFriends();
    // console.log(this.nonFriends)
  },

  methods: {
    showAlert(message) {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: message,
        showConfirmations: false,
        timer: 1500,
        confirmButtonColor: "#312E81",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1600);
    },

    getfile(media) {
      return this.url + `file?file=` + media;
    },

    getEvents() {
      this.axios
        .get(`events`)
        .then((response) => {
          this.events = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    suggestedGroups() {
      this.axios
        .get(`groups`)
        .then((response) => {
          this.groups = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    event() {
      this.$router.push("/Events");
    },

    discover() {
      this.$router.push("/Groups");
    },

    joinPublic(id) {
      this.axios
        .post(`group_users`, {
          group_id: id,
          user_id: this.authenticated.id,
        })
        .then((response) => {
          if(response.data.message == 'Sorry, group is full'){
            this.showAlert("Sorry, Group is Full");
            return;
          }
           this.showAlert("Successfully Joined, You are now a member");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    joinPrivate(id) {
      this.axios
        .post(`group/private/join`, {
          group_id: id,
          user_id: this.authenticated.id,
        })
        .then((response) => {
          if(response.data.message == 'Sorry, group is full'){
            this.showAlert("Sorry, Group is Full");
            return;
          }
          this.showAlert("Request Sent! Waiting For Approval");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getNonFriends() {
      this.axios
        .get(`add/friends`)
        .then((response) => {
          this.allNonFriends = response.data.data;
          this.nonFriends = this.allNonFriends.filter(function (e) {
            return !e.status;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    add(id) {
      this.axios
        .post(`friends`, {
          user_id: this.authenticated.id,
          friend_id: id,
          status: "sent",
        })
        .then(() => {
          this.showAlert("Friend Request sent");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
