<template>
<div id="nav" >
 <div class="header w-full py-3 md:px-10 bg-white flex items-center flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 top-0 shadow-md ">

        <div class="flex justify-between w-full md:w-40  px-4">
            <div class="logo flex justify-center">
            <router-link to="/Timeline"><img class="object-contain" src="@/assets/images/logo.png" alt="" srcset=""></router-link>    
            </div>
    
            <div class="mobile md:hidden" @click="open =!open">
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
                      </svg>
                </button>
            </div>
        </div>
       

        <div class=" md:block flex-1 " :class="{hidden:!open}">

            <div class="md:flex space-y-8 md:space-y-0  justify-between">
                <div class=" bg-gray-200 flex items-center rounded-lg ">
                    <input class="bg-gray-200 rounded-l-lg  w-full py-4 px-6 text-gray-700 leading-tight focus:outline-none" id="search" type="text" placeholder="Search....">
                    
                  
                      <button class="p-3" >
                          <img src="@/assets/images/search.svg" alt="" srcset="" >
                      </button>
                    
                    </div>
        
                      <div class="nav-link flex md:space-x-8 ">
                  
      
                        <ul class="nav-link flex font-bold list-none space-x-4 items-center ">
      
                      <li class="flex items-center">
                                <router-link to="/Timeline" class="flex items-center flex-col justify-center text-xs h-12 w-20 no-underline hover:text-indigo-900" @click="open=!open">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                              </svg>
                                <span>Timeline</span>
                            </router-link>
                            </li>


                            <li class="flex items-center">
                                <router-link to="/Groups" class="flex items-center flex-col justify-center text-xs h-12 w-20 no-underline hover:text-indigo-900" @click="open=!open">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                        </svg>
                                <span>Groups</span>
                            </router-link>
                            </li>
      
                            <li class="flex items-center">
                              <router-link  to="/Events" class="flex items-center flex-col justify-center text-xs h-12 w-20 no-underline hover:text-indigo-900" @click="open=!open">
                                 <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                              </svg>
                                  <span>Events</span>
                              </router-link >
                          </li>
            
                            <li class="flex items-center">
                                <router-link  to="/Friends" class="flex items-center flex-col justify-center text-xs h-12 w-20 no-underline hover:text-indigo-900" @click="open=!open"> 
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                        </svg>
                                    <span>Friends</span>
                                </router-link >
                
                            </li>
            
                            
            
                           
                        </ul>
                      </div>
      
                      <div class="righ-nav">
                         <ul class="flex items-center justify-center space-x-4">
                      <li class="rounded-full flex items-center  p-3 relative bg-indigo-900">
                          <img class="w-3 h-3" src="@/assets/images/chat.svg" alt="" srcset="">
      
      
                           <div class="notification-counter absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full items-center justify-center flex">
                           <span  class="text-xs text-white cursor-pointer">1</span>
                           </div>
                      </li>
          
                      <li class="rounded-full p-3 bg-indigo-900 relative">
                          <img class="w-3 h-3 " src="@/assets/images//bell.svg" alt="" srcset="">
      
                          <div class="notification-counter absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full items-center justify-center flex">
                              <span  class="text-xs text-white cursor-pointer">4</span>
                              </div>
                      </li>
          
                      <li class=" flex  items-center space-x-2">
                             <div class="user-profile relative" @click="!toggle">
                <button
            class="
              flex flex-row
              items-center
              w-full
              text-xs
              font-semibold
              text-left
              bg-transparent
              md:block
              hover:text-gray-900 
            "
            @click="toggle = !toggle"
          >
              <div class="flex items-center space-x-2">
<img v-if="authenticatedUser && authenticatedUser.profile.image" class="  w-9 h-9  object-cover rounded-full" :src="profilePic(authenticatedUser.profile.image)" alt="" srcset="">
<img v-else class="  w-9 h-9  object-cover rounded-full" src="@/assets/images/default.png" alt="" srcset="">
            <span class="text-gray-700">Hi {{authenticatedUser.name}}</span>
              <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              :class="{ 'rotate-180': toggle, 'rotate-0': !toggle }"
              class="
                inline
                w-4
                h-4
                transition-transform
                duration-200
                transform
                md:-mt-1
                text-gray-700
              "
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
              </div>
            
          
                 </button>

                     <div
            x-show="toggle"
            x-transition:enter="transition ease-out duration-100"
            x-transition:enter-start="transform opacity-0 scale-95"
            x-transition:enter-end="transform opacity-100 scale-100"
            x-transition:leave="transition ease-in duration-75"
            x-transition:leave-start="transform opacity-100 scale-100"
            x-transition:leave-end="transform opacity-0 scale-95"
            class="
              absolute
              right-0
              w-full
              mt-2
              origin-top-right
              rounded-md
              shadow-lg
              z-10
            "
            :class="{ hidden: !toggle }"
          >
            <div
              class="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800"
            >

              <router-link
                class="
                  block
                  text-gray-700
                  px-4
                  py-2
                  mt-2
                  text-sm
                  font-semibold
                  bg-transparent
                  rounded-lg
                  md:mt-0
                  hover:text-gray-900
                  focus:text-gray-900
                  hover:bg-wiifarm-form
                  focus:bg-wiifarm-form focus:outline-none focus:shadow-outline
                "
                to="Profile"
                @click="toggle=!toggle">Profile</router-link>


              <router-link
                class="
                  block
                  text-gray-700
                  px-4
                  py-2
                  mt-2
                  text-sm
                  font-semibold
                  bg-transparent
                  rounded-lg
                  md:mt-0
                  hover:text-gray-900
                  focus:text-gray-900
                  hover:bg-wiifarm-form
                  focus:bg-wiifarm-form focus:outline-none focus:shadow-outline
                "
                to="/"
                @click="toggle=!toggle">Logout</router-link
              >
             
            </div>
                   </div>
                        </div>
      
                          
                      </li>

                     
                      </ul>
                 
                  
                    </div>
      
            </div>

            
            
        </div>
        
           

    </div>

    
</div>
  
</template>

<script>

  import { mapGetters } from 'vuex'

export default {
  name: 'Header',

  data() {
    return {
      open: false,
      toggle: false,
      user: '',
      url: process.env.VUE_APP_BASE_URL,
      profile: [],
      auth_user_role: '',
      isAdmin: false,
      authenticatedUser: ''
    }
  },

   computed: {
     ...mapGetters(['authenticated', 'role'])     
},

  mounted() {
    this.authenticatedUser = this.authenticated
    this.auth_user_role = this.role ? this.role.role.permissions : ''
    this.permission()

  },

  methods:{

    permission(){
    if(this.auth_user_role && this.auth_user_role.indexOf("can_see_admin_dashboard") !== -1){
        this.isAdmin = true
    }
  },

    logout() {
     this.axios.post(`logout`)
      .then(() => {
        this.$store.commit('logout')
        this.$router.push('/')
      })
      .catch((error) => {
        console.log(error)
      })
    },

   profilePic(media){
      return this.url + `file?file=`+media    
    },

    admin() {
      this.axios.get(`admin`)
      .then((response) => {
        window.location.href = response.data.data   
      })
      .catch((error) => {
        console.log(error)
      })
    },

    // getProfile() {
    //   this.axios.get(`users/`+ this.authenticated.id)
    //   .then((response) => {
    //     this.user = response.data.data
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
    // },


  }
  
}
</script>


<style lang="">

</style>