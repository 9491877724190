<template>
  <div class="">
  
    <div class="grid md:grid-cols-2 w-full h mx-auto items-center p-4 md:p-10">
       
<div class=" space-y-8 text-left p-4">

        <img class="w-48" src="@/assets/images/logo.png" alt="" srcset="">

         <h1 class="md:text-4xl text-gray-700">We aim to foster relationships amongst
        christians.</h1>

        </div>


    
            <div class=" flex flex-col w-full space-y-4 overflow-hidden p-10  rounded-lg bg-white">
                <div class="flex items-center justify-center  text-2xl space-x-4 pt-8">
                    <img class="w-14 " src="@/assets/images/logo.jpg" alt="">
                    <h1 class="text-center font-sans font-medium  text-indigo-900">Change Password </h1>
                </div>
           
         
         

  <form class="w-full lg:px-10" @submit.prevent="reset">
  <p v-if="state.error" class="text-xs italic text-red-500">{{state.error}}</p>
    <div class="flex flex-wrap mb-4">
    <div class="w-full  mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
      New Password
      </label>
      <input id="email" v-model="state.password" class="appearance-none block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  type="password" placeholder="">
       <div class="input-errors" v-for="(error, index) of v$.password.$errors" :key="index">
            <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
      </div>
    </div>

    <div class="w-full  mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
      Confirm New Password
      </label>
      <input id="email" v-model="state.password_confirmation" class="appearance-none block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  type="password" placeholder="">
      <div class="input-errors" v-for="(error, index) of v$.password.$errors" :key="index">
            <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
      </div>
    </div>

    <div class="text-xs flex items-center justify-between w-full">
 <span v-if="state.success" class="text-xs italic text-red-500">Password successfully changed!</span>

  <span> <router-link to="/" class="text-indigo-800 hover:underline">Login here.</router-link></span>
    </div>
    

  </div>

  



  <div class="flex justify-center mb-4">
<button type="submit" class=" bg-indigo-900 py-3 w-full rounded-md text-white">Change Password</button>
  </div>


          </form>



          </div>

    </div>
  
  </div>
</template>


<script>
    import { useVuelidate } from '@vuelidate/core'
    import { required, minLength, sameAs } from '@vuelidate/validators'
    import { reactive, computed } from 'vue'

export default {
    name: 'ChangePassword',

  setup () {
    const state = reactive({
        password: '',
        password_confirmation: '',
        token: '',
        error: '',
        success: '',
  })

  const rules = computed(() => {
      return {
          password: { required, minLength: minLength(6)},
          password_confirmation: {required, sameAs: sameAs(state.password) }
      }
    })

    const v$ = useVuelidate(rules, state)

    return {
      state,
      v$
    }
  },
    

  methods:{

    reset() {
      this.v$.$validate()
      if(!this.v$.$error){
        this.state.token = this.$route.params.id
         this.axios.post(`reset-password`, this.state)
          .then(() => {
          this.state.success= true
          this.$router.push('/')
          })
          .catch((error) => {
             if(error.response.status === 422){
              if(error.response.data.errors.token == "The selected token is invalid."){
                this.state.error = "Token is invalid."
                console.log(this.state.error)
              }
              else if(error.response.data.errors.email == "The email field is required."){
                this.error= "The email field is required."
              }
            }
          })    
      }
    
    
    },

  }
}
</script>