<template>
  <div class="mt-10">
    <div class="bg-white rounded-t-3xl p-8 space-y-8 shadow-md mb-20">
      <div class="flex items-center justify-end">
        <div v-if="create_event" class="button">
          <button
            class="
              flex
              space-x-2
              items-center
              px-6
              py-3
              border
              rounded-2xl
              border-indigo-900
              text-indigo-900 
              shadow-md
              hover:bg-indigo-100
            "
            @click="$refs.modalName.openModal()"
          >
            <img src="@/assets/images/plus.svg" alt="" srcset="" />
            <span>Create Event</span>
          </button>
        </div>
      </div>


      <tabs :options="{ useUrlFragment: false }">
        <tab class="space-y-8" name="All Events">
          <div class="space-y-4">
            <h1 class="text-indigo-900 text-xl">Most Recent Events</h1>

            <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
              <AllEventCard />
              <!-- <AllEventCard /> -->
            </div>
          </div>

          <!-- <div class="space-y-4">
            <h1 class="text-indigo-900 text-xl">Upcoming events </h1>
      
          


          <div class="grid gap-4  grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            
         <AllEventCard />
          <AllEventCard />
            

          </div>

            </div> -->
        </tab>

        <tab class="space-y-8" name="Registered Events">
          <div class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <RegisteredEventCard />
          </div>
        </tab>
        <!-- 
                <tab class="space-y-8" name="Events Created">


                </tab> -->
      </tabs>
    </div>
  </div>

  <modal ref="modalName">
    <template v-slot:header>
      <div class="text-center">
        <h1 class="text-center font-sans text-2xl text-wiifarm-500">
          Creat an Event
        </h1>
      </div>
    </template>

    <template v-slot:body>
      <form class="w-full" method="POST" @submit.prevent="create">
        <div
          class="flex flex-wrap mb-6"
          :class="{ error: v$.form.title.$errors.length }"
        >
          <div class="w-full px-3 mb-6 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Title
            </label>
            <input
              v-model="v$.form.title.$model"
              id="name"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="text"
              placeholder="John"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.title.$errors"
              :key="index"
            >
              <p class="error-msg text-red-500 text-xs italic">
                {{ error.$message }}.
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="w-full px-3 mb-6 md:mb-0"
            :class="{ error: v$.form.location.$errors.length }"
          >
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Location
            </label>
            <input
              v-model="v$.form.location.$model"
              id="name"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="text"
              placeholder="John"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.location.$errors"
              :key="index"
            >
              <p class="error-msg text-red-500 text-xs italic">
                {{ error.$message }}.
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="w-full px-3 mb-6 md:mb-0"
            :class="{ error: v$.form.start_time.$errors.length }"
          >
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Start Time
            </label>
            <input
              v-model="v$.form.start_time.$model"
              id="name"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="time"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.start_time.$errors"
              :key="index"
            >
              <p class="error-msg text-red-500 text-xs italic">
                {{ error.$message }}.
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              End Time
            </label>
            <input
              v-model="form.end_time"
              id="name"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="time"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div
            class="w-full px-3 mb-6 md:mb-0"
            :class="{ error: v$.form.start_date.$errors.length }"
          >
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Start Date
            </label>
            <input
              v-model="v$.form.start_date.$model"
              id="name"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="date"
              placeholder="John"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.start_date.$errors"
              :key="index"
            >
              <p class="error-msg text-red-500 text-xs italic">
                {{ error.$message }}.
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              End Date
            </label>
            <input
              v-model="form.end_date"
              id="name"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="date"
              placeholder="John"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Seats
            </label>
            <input
              id="name"
              v-model="form.seats"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="number"
              placeholder="800"
            />
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Description
            </label>
            <textarea
              id="name"
              v-model="form.details"
              rows="4"
              cols="50"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="text"
              placeholder="John"
            ></textarea>
          </div>
        </div>

        <div class="flex flex-wrap mb-6">
          <div class="w-full px-3 mb-6 md:mb-0">
            <label
              class="block tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="firstname"
            >
              Image
            </label>
            <input
              @change="uploadFile"
              id="name"
              class="
                appearance-none
                block
                w-full
                text-gray-700
                shadow-sm
                border
                focus:border-indigo-700
                rounded
                py-3
                px-4
                mb-3
                leading-tight
                text-xs
                focus:outline-none
                focus:bg-indigo-50
                focus:ring-indigo-700
                focus:ring-1
              "
              type="file"
              placeholder="John"
            />
          </div>
        </div>

        <div class="flex justify-center mb-4">
          <button
            type="submit"
            :disabled="v$.form.$invalid"
            class="bg-indigo-900 py-3 w-full rounded-md text-white"
          >
            Create
          </button>
        </div>
      </form>
    </template>

    <!-- <template v-slot:footer>
        
      </template> -->
  </modal>
</template>


<script>
import Modal from "../components/Modal.vue";
import AllEventCard from "../components/AllEventCard.vue";
import RegisteredEventCard from "../components/RegisteredEventCard.vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import moment from "moment";

export default {
  name: "Events",

  components: {
    AllEventCard,
    RegisteredEventCard,
    Modal,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      events: [],
      mineEvents: [],
      form: {
        title: "",
        location: "",
        start_time: "",
        end_time: "",
        start_date: "",
        end_date: "",
        seats: "",
        details: "",
        created_by: "",
      },
      files: "",
      url: process.env.VUE_APP_BASE_URL,
      auth_user_role: "",
      create_event: false,
    };
  },

  validations: {
    form: {
      title: { required },
      location: { required },
      start_time: { required },
      start_date: { required },
    },
  },

  computed: {
    ...mapGetters(["authenticated", "role"]),
  },

  mounted() {
    this.getEvents();
    this.registeredEvents();
    this.form.created_by = this.authenticated.id;
    this.auth_user_role = this.role ? this.role.role.permissions : "";
    this.permission();
  },

  methods: {
    permission() {
      if (
        this.auth_user_role &&
        this.auth_user_role.indexOf("can_create_event") !== -1
      ) {
        this.create_event = true;
      }
    },

    formatDate(value) {
      if (value) {
        return moment(value).fromNow();
      }
    },

    showAlert() {
      this.$swal({
        position: "top-end",
        icon: "success",
        title: "Event Created",
        showConfirmations: false,
        timer: 1500,
        confirmButtonColor: "#312E81",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1600);
    },

    getfile(media) {
      return this.url + `file?file=` + media;
    },

    getEvents() {
      this.axios
        .get(`events`)
        .then((response) => {
          this.events = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    registeredEvents() {
      this.axios
        .get(`event_users`)
        .then((response) => {
          this.mineEvents = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    uploadFile(event) {
      this.files = event.target.files[0];
    },

    create() {
      const formData = new FormData();

      formData.append("title", this.form.title);
      formData.append("location", this.form.location);
      formData.append("start_time", this.form.start_time);
      formData.append("end_time", this.form.end_time);
      formData.append("start_date", this.form.start_date);
      formData.append("end_date", this.form.end_date);
      formData.append("details", this.form.details);
      formData.append("seats", this.form.seats);
      formData.append("created_by", this.form.created_by);
      formData.append("files", this.files);

      const config = { headers: { "content-type": "multipart/form-data" } };

      this.axios
        .post(`events`, formData, config)
        .then(() => {
          this.showAlert();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    view(id) {
      this.$router.push("/event/" + id);
    },

    register(id) {
      this.axios
        .post(`event_users`, {
          event_id: id,
          user_id: this.form.created_by,
        })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancel(id) {
      this.axios
        .delete(`event_users/` + id)
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>