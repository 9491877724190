import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import axios from '@/axios'
import './assets/tailwind.css'
import './assets/style.css'
import './assets/theme.css'
import {Tabs, Tab} from 'vue3-tabs-component'
import VueSweetAlert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Popper from "vue3-popper"
// createApp(App)
// .use(router).use(Vuetabs)
// .mount('#app')

const app = createApp(App);
app.use(store);
app.config.globalProperties.axios=axios;
app.component("Popper", Popper);

app.use(router)
.component('tabs', Tabs)
.component('tab', Tab)
.use(VueSweetAlert2, options)
.mount('#app')



//sweet Alert color change
const options = {
    confirmButtonColor:'#312E81',
    cancelButtonColor:'#EF4444',
};
axios.interceptors.request.use((config) => {
    const token = store.getters.token;
  
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  
    return config;
  });
