<template>
    <div id="event-details">
  <main class=" m-10">
 

      <div class="w-8/12 mx-auto rounded-md p-8 space-y-4 ">
        <router-link class="hover:text-indigo-500" to="/Groups">
         <button class="text-xs flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
</svg>

<span>Back</span>
          </button>
        
        </router-link>   
        
           

        <div class="rounded-lg">
           
            <div class="flex-1 bg-white text-sm space-y-2 p-6 rounded-lg shadow-lg border">
                 <h1 class="text-3xl pb-4 text-indigo-900">Invite Members</h1>
             <table class=" w-full table-auto ">
             <thead >
                  <tr class=" bg-indigo-900 text-white text-xs leading-normal text-wiifarm-500">
                    <td class="p-3"> S/N  </td>
                    <td class="p-3 text-left">Name</td>

         
                     <td class="p-3 "> Action</td>

                    </tr>
                </thead>

                 <tbody class="text-gray-600 text-xs font-medium">
                
                  
                    <tr v-for="(user, index) in nonMembers" :key="user.id" class="border-b mb-10 border-gray-200">
                       <td class="p-3"> {{ index + 1}} </td>
                    <td class="p-3 flex items-center "> <img v-if="user.profile.image" class="h-8 w-8 mr-2 rounded-lg" :src="getfile(user.profile.image)" alt="" srcset=""> {{ user.name }} </td>
                    
                    
                    <td class="p-3">
                  <button @click.prevent="invite(user.id)"> <span class="px-6 py-2 bg-indigo-900 rounded-md text-white" >Invite</span></button>
                   

                
                      </td>
         

                  </tr>


 
        
               
                </tbody>
                  </table>
            </div>
        </div>
         
      </div>
    </main>
    </div>
</template>


<script>

    import { mapGetters } from 'vuex'
    import moment from 'moment'
export default {
    name: 'InviteMembers',

  data() {
    return {
      nonMembers: [],
      url: process.env.VUE_APP_BASE_URL,
      group: '',
      authUser: ''
    }
  },

  computed: {
     ...mapGetters(['authenticated'])     
},

   mounted() {
    this.authUser = this.authenticated.id
    this.users()
    //this.getGroup()
  },

    methods:{
 showAlert(){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: 'Member Successfully Invited',
       showConfirmations: false,
       timer: 1500,
        confirmButtonColor: '#312E81'
   });

   setTimeout(() => {
       window.location.reload();
     }, 1600);

      },

     formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },

    getfile(media){
      return this.url + `file?file=`+media    
    },

    users() {
      this.axios.get(`group/`+ this.$route.params.id +`/non_members`)
      .then((response) => {
        this.nonMembers = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    // getGroup() {
    //   this.axios.get(`group/`+ this.$route.params.id)
    //   .then((response) => {
    //     this.group = response.data.data
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
    // },

    invite(id){
    //    if(this.group.public == true){
    //       this.axios.post(`group_users`, {
    //       group_id: this.$route.params.id,
    //       user_id: id
    //     })
    //     .then(() => {       
    //         window.location.reload();
    //     })
    //     .catch((error) => {
    //         console.log(error)
    //     })
    // } if(this.group.public == false && this.group.visible == false){
    //       this.axios.post(`group/private/add`, {
    //       group_id: this.$route.params.id,
    //       user_id: id
    //     })
    //     .then(() => {       
    //         window.location.reload();
    //     })
    //     .catch((error) => {
    //         console.log(error)
    //     })
    // }else {
    //     this.axios.post(`group/private/join`, {
    //       group_id: this.$route.params.id,
    //       user_id: id
    //     })
    //     .then(() => {       
    //       window.location.reload();
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     })
    // }

     this.axios.post(`group_invites`, {
          group_id: this.$route.params.id,
          invitee: id,
          invited_by : this.authUser,
          status: 'pending'
        })
        .then(() => {       
            this.showAlert()
        })
        .catch((error) => {
            console.log(error)
        })

    }
    }
}
</script>