<template>
    <div id="Profile">
  <main class=" my-10">
      <div class="w-3/5 mx-auto">

            <div class="relative">
              <div class="bg-gray-200 rounded-t-xl  h-64">
            
                 <div class="profile-image">
                <img v-if="profile.image" :src="profilePic(profile.image)" alt="jpg" class="h-64 w-full bg-cover rounded-t-xl " srcset="">
                
                <img v-else-if="profile.image == '' || !profile.image" alt="image" class="h-64 w-full bg-cover rounded-t-xl " src="@/assets/images/default.png" srcset="">
              </div> 

              </div>
                        
           </div>
    
          <div class="bg-white relative p-10 space-y-8 rounded-b-xl mb-4">

              <div class="profile-image">
                <img v-if="profile.image" :src="profilePic(profile.image)" alt="jpg" class="w-28 h-28 absolute -top-12  rounded-full p-1 bg-white" srcset="">
                
                <img v-else-if="profile.image == '' || !profile.image" alt="image" src="@/assets/images/default.png" class="w-28 h-28 absolute -top-12  rounded-full p-1 bg-white" srcset="">
              </div> 
              
          <h1 class="text-lg">{{user.name}}</h1>
           
           <form class="grid grid-cols-1 md:grid-cols-2 text-xs overflow-hidden" action="">
 
          <div class=" flex flex-col space-y-4 p-2">
              <div class="text-md" style="overflow-wrap:break-word;">
           <label class="font-bold" for="">Name: </label> 
           <span >{{ user.name }}</span>

           
            </div>

            <div class="text-md" style="overflow-wrap:break-word;">
           <label class="font-bold" for="">Email: </label> 
           <span  >{{ user.email }}</span>

        
            </div>


         <div class="text-md" style="overflow-wrap:break-word;">
           <label class="font-bold" for="">Phone No: </label> 
           <span >{{ profile.phone ? profile.phone : 'Rather Not Say' }}</span>

        
            </div>
        
        
         <div class="text-md" style="overflow-wrap:break-word;">
           <label class="font-bold" for="">Country: </label> 
           <span >{{ profile.country ? profile.country : 'Rather Not Say' }}</span>

        
            </div>
        


         <div class="text-md" style="overflow-wrap:break-word;">
           <label  class="font-bold" for="">State: </label> 
           <span >{{ profile.state ? profile.state : 'Rather Not Say'}}</span>

          
            </div>
               
          </div>

          <div class="flex flex-col space-y-4 p-2">

         <div class="text-md " style="overflow-wrap:break-word;">
           <label class="font-bold" for="">City: </label> 
           <span >{{ profile.city ? profile.city : 'Rather Not Say'}}</span>

       
            </div>

          <div class="text-md " style="overflow-wrap:break-word;">
           <label class="font-bold" for="">Marital Status: </label> 
           <span  >{{ profile.martial_status ? profile.martial_status : 'Rather Not Say'}}</span>

         
            </div>
        
          
         <div class="text-md" style="overflow-wrap:break-word;">
           <label class="font-bold" for="">Hobbies: </label> 
           <span >{{ profile.hobbies ? profile.hobbies : 'Rather Not Say'}}</span>


            </div>
        
          
         <div class="text-md " style="overflow-wrap:break-word;">
           <label class="font-bold" for="">Bio: </label> 
           <span >{{ profile.bio ? profile.bio : 'Rather Not Say'}}</span>

 
            </div>
      
          </div>

          
           </form>
        
       
          </div>


         
      </div>
    </main>
    </div>
</template>


<script>

    import moment from 'moment'
    import { mapGetters } from 'vuex'
    import { useVuelidate } from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'

export default {
    name: 'Profile',

    setup () {
      return { v$: useVuelidate() }
    },

    data(){
      return {
        authenticatedUser: '',
        profile: [],
        url: process.env.VUE_APP_BASE_URL,
        preview: '',
        Posts:[],
        groups:[],
        friends:[],
        editField : '',
        user: ''
      }
    },

  validations: {
    form: {
        name: { required },
        email: { required, email },
      }
  },

  computed: {
     ...mapGetters(['authenticated'])     
},

mounted() {
    this.authenticatedUser = this.authenticated
    //this.authUser()
    this.getProfile()
  },

    methods:{
      focusField(name){
        this.editField = name;
      },
      blurField(){
        this.editField ='';
      },
      showField(name){
        return (this.user[name] == '' || this.editField == name)
      },

      formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },

    getProfile() {
      this.axios.get(`users/`+ this.$route.params.id  )
      .then((response) => {
        this.profile = response.data.data.profile 
        this.user = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    profilePic(media){
      return this.url + `file?file=`+media    
    },

    }




}
</script>