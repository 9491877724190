<template>
   

              

               
                    <tr v-for="(friend, index) in friendsRequest" :key="friend.id" class="border-b mb-10 border-gray-200">
                       <td class="p-3"> {{ index + 1}}  </td>
                    <td class="p-3 flex items-center "> 
                      <img v-if="friend.user.profile.image" class="h-8 w-8 mr-2 rounded-lg" :src="getfile(friend.user.profile.image)" alt="" srcset="">
                  <img v-else class="h-8 w-8 mr-2 rounded-lg" src="@/assets/images/default.png" alt="" srcset="">
                      </td>
                    <td class="p-3 text-left">{{ friend.user.name }}</td>
                    <td class="p-3 text-left">{{ friend.user.profile.phone ? friend.user.profile.phone : 'Rather not say' }}</td>
                    <td class="p-3 text-left">{{ friend.user.profile.marital_status ? friend.user.profile.marital_status : 'Rather not say' }}</td>
                   



                    <td class="p-3">
                        <div class="w-full">
                <Popper  arrow hover>
                <button>    <img src="@/assets/images/3-dots.svg" alt="" srcset=""></button>
                <template #content="{close}">
      
                    <div class="text-sm text-center flex flex-col space-y-3">
                 <a href="#" v-on:click="close" @click.prevent="accept(friend.id)" class="hover:text-indigo-500 w-full">Accept 😎</a>
              <router-link :to="{name: 'FriendProfile', params: {id: friend.user.id}}" class="hover:text-indigo-500 w-full">View Profile</router-link>
                
                    </div>
                
            </template>
                </Popper> 

               </div>
                      </td>
         

                  </tr>








</template>



<script>
    import { mapGetters } from 'vuex'
    import moment from 'moment'
export default {
    name: 'FriendRequest',

  data() {
    return {
    toggle : false,
      friendsRequest: [],
      url: process.env.VUE_APP_BASE_URL,
      authenticatedUser: '',
    }

},

 computed: {
     ...mapGetters(['authenticated'])     
},

 mounted() {
    this.authenticatedUser = this.authenticated
    this.getFriendsRequest()
},

 methods:{
 showAlert(message){
   this.$swal({
       position: 'top-end',
       icon: 'success',
       title: message,
       showConfirmations: false,
       timer: 1500,
        confirmButtonColor: '#312E81'
   });

    setTimeout(() => {
       window.location.reload();
     }, 1600);


      },

    formatDate(value){
      if (value) {
            return moment(value).fromNow()
        }
    },
    getfile(media){
      return this.url + `file?file=`+media    
    },

    getFriendsRequest() {
      this.axios.get(`friends-request`)
      .then((response) => {
        this.friendsRequest = response.data.data
      })
      .catch((error) => {
        console.log(error)
      })
    },

    accept(id){
      this.axios.post(`friends/`+id +`/accept`)
      .then(() => {        
        this.showAlert('Friend Request Accepted')
      })
      .catch((error) => {
        console.log(error)
      })
    },
  }


}


</script>