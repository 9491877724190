<template>
  <div class="">
  
    <div class="grid grid-cols-1 md:grid-cols-2 w-full mx-auto items-center p-4 md:p-10">
 <div class=" space-y-8 text-left p-4">

        <img class="w-48" src="@/assets/images/logo.png" alt="" srcset="">

         <h1 class="md:text-4xl text-gray-700">We aim to foster relationships amongst
        christians.</h1>

        </div>     
            <div class=" flex flex-col w-full space-y-4 overflow-hidden p-4 md:p-10  rounded-lg bg-white">

  <form class="w-full lg:px-10" method="POST" @submit.prevent="reset">
  
    <div class="flex flex-wrap mb-4">
      <div class="w-full  mb-6 md:mb-0">
        <p v-if="state.error" class="error-msg text-red-500 text-xs italic">{{state.error}}</p>
        <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
        Email
        </label>
        <input id="email" v-model="state.email" class="appearance-none block w-full  text-gray-700 hadow-sm    border focus:border-indigo-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-indigo-50 focus:ring-indigo-700 focus:ring-1 text-xs"  type="email" placeholder="john@gmail.com">
         <div class="input-errors" v-for="(error, index) of v$.email.$errors" :key="index">
            <p class="error-msg text-red-500 text-xs italic">{{ error.$message }}.</p>
          </div>
        <span v-if="state.success" class="text-xs italic text-red-500">A link has been sent to your mail, follow link to reset password!</span>
      </div>

  </div>
  <div class="flex justify-center mb-4">
<button type="submit" class=" bg-indigo-900 py-3 w-full rounded-md text-white">Reset Password</button>
  </div>


          </form>



          </div>


    </div>
  
  </div>
</template>


<script>

  import { useVuelidate } from '@vuelidate/core'
  import { required, email } from '@vuelidate/validators'
  import { reactive, computed } from 'vue'

export default {
    name: 'ForgotPassword',

    setup () {
    const state = reactive({
        email: '',
        error: '',
        success:false
  })

   const rules = computed(() => {
      return {
          email: { required, email },
      }
    })

    const v$ = useVuelidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods:{

    reset() {
      this.v$.$validate()
      if(!this.v$.$error){
        this.axios.post(`forgot-password`, {
            email: this.state.email
          })
          .then(() => {
          this.state.success= true
          })
          .catch((error) => {
            if(error.response.status === 422){
              if(error.response.data.errors.email == "No user found with the email supplied"){
                this.state.error= "No user found with the email supplied."
              }
            }
          })
      }
    },

  }
}
</script>